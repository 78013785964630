import React, { useState, useEffect, useContext, useRef, useLayoutEffect, useMemo } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { UncontrolledTooltip, Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { TicketContext } from "./TicketContext";
import {
  serviceDeleteTickets,
  serviceGetAllTickets,
  serviceGetTicketSummary,
  serviceGetTickets,
  serviceGetTicketsByField,
  serviceGetTicketsByQuery,
  serviceGetTicketsById,
  serviceGetTicketByIds,
} from "services/tickets";
import { serviceGetChatById } from "services/chats";
import { useCookies } from "react-cookie";
import { getDepartments, getDepartmentsAll } from "services/departments";
import getTextColor from "components/custom/getTextColor";
import moment from "moment";
import Swal from "sweetalert2";
import SimpleBar from "simplebar-react";
import { serviceGetCampaigns, serviceGetListCampaigns } from "services/campaign";
import { getTagsForTickets } from "services/tags";
import Papa from "papaparse";
import { ChatContext } from "../chat/ChatContext";
import { MessagesContext } from "contexts/messagesContext";
import { useLocation } from "react-router-dom";
import { utilNumberPhoneConvention } from "../chat/utils/ChatUtils";

const statusOption = [
  { value: "ALL", label: "ALL" },
  { value: "RESOLVED", label: "RESOLVED" },
  { value: "PENDING", label: "PENDING" },
  { value: "UNOPENED", label: "UNOPENED" },
  { value: "OPENED", label: "OPENED" },
];

const ratingOption = [
  { value: "ALL", label: "ALL" },
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

const CampaignBadge = (campaign, item) => {
  switch (campaign) {
    case "Instagram":
      return <div className="badge campaign-badge__instagram">Instagram</div>;
    case "Facebook":
      return <div className="badge campaign-badge__facebook">Facebook</div>;
    case "Youtube":
      return <div className="badge campaign-badge__youtube">Youtube</div>;
    default:
      return <div className="badge campaign-badge__whatsapp">{campaign}</div>;
  }
};

const Ticket = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const [cookies] = useCookies(["user"]);
  const { contextData } = useContext(TicketContext);
  const { messagesValue, salesUpdate, ticketUpdate } = useContext(MessagesContext);
  const [data, setData] = contextData;
  const [campaignOption, setCampaignOption] = useState([{ value: "ALL", label: "ALL" }]);
  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(false);
  const [onSearchText, setOnSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [onQuerySearch, setOnQuerySearch] = useState("");
  const [summaryData, setSummaryData] = useState({
    opened: 0,
    pending: 0,
    resolved: 0,
    unopened: 0,
  });
  const [departmentData, setDepartmentData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [sortKeyword, setSortKeyword] = useState("");
  const [activeSearch, setActiveSearch] = useState("");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["tickets"].access || [];
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 0,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const { search } = useLocation();
  const ticketStatusParam = new URLSearchParams(search).get("ticketStatus");
  const [orderBy, setOrderBy] = useState("DESC");
  const [businessOption, setbusinessOption] = useState([]);
  const [leadSourceOption, setLeadSourceOption] = useState([]);
  const [prospectOption, setProspectOption] = useState([]);
  const [isSorting, setIsSorting] = useState(false);

  const [queryId, setQueryId] = useState("");
  const [queryName, setQueryName] = useState("");
  const [queryContact, setQueryContact] = useState("");
  const [queryPhone, setQueryPhone] = useState("");
  const [showPlus, setShowPlus] = useState(true);
  const [queryLeadSource, setQueryLeadSource] = useState("");
  const [queryProspect, setQueryProspect] = useState("");
  const [queryCampaign, setQueryCampaign] = useState("");
  const [queryStatus, setQueryStatus] = useState(ticketStatusParam ? ticketStatusParam : "");
  const [queryRating, setQueryRating] = useState("");
  const [queryPic, setQueryPic] = useState("");
  const [queryBusiness, setQueryBusiness] = useState("");

  const [fieldName, setFieldName] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [fieldContact, setFieldContact] = useState("");
  const [fieldPhone, setFieldPhone] = useState("");
  const [fieldLeadSource, setFieldLeadSource] = useState("");
  const [fieldProspect, setFieldProspect] = useState("");
  const [fieldCampaign, setFieldCampaign] = useState("");
  const [fieldStatus, setFieldStatus] = useState("");
  const [fieldRating, setFieldRating] = useState("");
  const [fieldPic, setFieldPic] = useState("");
  const [queryText, setQueryText] = useState("");
  const [fieldText, setFieldText] = useState("");
  const [chatbotTicket, setChatbotTicket] = useState([]);
  const [newMessages, setNewMessages] = messagesValue;
  const [newSales] = salesUpdate;
  const [newTicket] = ticketUpdate;

  const [campaignPage, setCampaignPage] = useState(0);
  const [runGetCampaign, setRunGetCmapaign] = useState(0);

  const refresthDepartmentData = async () => {
    const responseGetUser = await getTagsForTickets(cookies.SIDikey);
    const responseGetDepartments = await getDepartments(
      cookies.SIDikey,
      "",
      cookies.departmentIds,
      cookies.id,
      roleFunctions
    );
    setDepartmentData(responseGetDepartments.data.data);
    setTagsData(responseGetUser.data.data);
  };

  useEffect(() => {
    refresthDepartmentData();
  }, []);

  useEffect(() => {
    
    if (data.length <= 0) return;
    
    if (newMessages.length > 0) {

      const lastMessage = newMessages.at(-1);
 
      //jika pesan dari bot dan tidak memiliki akses view_chatbot
      if(!roleFunctions.includes("View_Chatbot") && lastMessage?.chat?.userId === "3ef9f486-ddad-46ed-b568-f7b32a62262f"){
        return;
      }

      if (roleFunctions.includes("Access_Own_Dept") && !(cookies.departmentIds.includes(lastMessage?.chat?.channel?.departmentId))) {
        return;
      }
      
      const matchDataWithMessage = data.findIndex((ticket) => ticket.id === lastMessage.ticketId);
      if (newMessages.at(-1).fromMe && lastMessage.type === "TEXT") {
        if (lastMessage.text.includes("Terima kasih telah memberikan penilaian Anda") && matchDataWithMessage >= 0) {
          updateRatingByTicket(lastMessage, matchDataWithMessage, data);
        }
        return;
      }

      if (lastMessage.type === "tag-update") {
        // update tag based on chat
        if (matchDataWithMessage >= 0 && data[matchDataWithMessage].status !== "RESOLVED") {
          const updateData = [...data];
          updateData[matchDataWithMessage].tags = lastMessage.tags;
          setData(updateData);
        }
        return;
      }
      
      if (lastMessage.type === "tag-update-resolve") {
        if (matchDataWithMessage >= 0) {
          const updateData = [...data];
          updateData[matchDataWithMessage].status = "RESOLVED";
          setData(updateData);
        }
        return;
      }

      if (lastMessage.type === "status-update") {
        if (matchDataWithMessage >= 0) {
          const updateData = [...data];
          updateData[matchDataWithMessage].status = lastMessage?.chat?.status;
          setData(updateData);
        }
        return;
      }

      if (matchDataWithMessage >= 0) {
        // update user and status from chat page
        const updateData = [...data];
        // const messageContent = lastMessage.content ? JSON.parse(lastMessage.content) : "";
        updateData[matchDataWithMessage].status = lastMessage?.chat?.status;
        updateData[matchDataWithMessage].user = lastMessage?.chat?.user;

        setData(updateData);
      }

      //jika ada perpindahan sales
      if (roleFunctions.includes("Access_Own") && lastMessage?.chat?.userId !== cookies.id ) {
        if(matchDataWithMessage>=0){
          const updateData = [...data];
          updateData.splice(matchDataWithMessage, 1);
          setData(updateData);
        }
        else return;
      }
      
      if (roleFunctions.includes("Access_Own") && lastMessage?.chat?.userId === cookies.id ) {
        const matchDataWithChatbotTicket = chatbotTicket.find((item) => item === lastMessage?.chat.id);
        if (matchDataWithChatbotTicket || lastMessage.type === "PURCHASE") {
          // add ticket after chatbot to sales
          getNewTicket(lastMessage?.chat.id);
        }
      }

      if (roleFunctions.includes("Access_Own_Dept") && cookies.departmentIds.includes(lastMessage?.chat?.channel?.departmentId)) {
        const matchDataWithChatbotTicket = chatbotTicket.find((item) => item === lastMessage?.chat.id);
        if (matchDataWithChatbotTicket || lastMessage.type === "PURCHASE") {
          // add ticket after chatbot to sales
          getNewTicket(lastMessage?.chat.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessages]);

  useEffect(() => {
    if (!salesUpdate[0] || data.length <= 0) return;
    salesUpdate.map((salesArr) => {
      if (!salesArr) return false;
      ticketUpdateBySales(salesArr);

      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSales]);

  useEffect(() => {
    if (data.length <= 0) return false;
    ticketUpdateNew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTicket]);

  const updateRatingByTicket = async (lastMessage, matchDataWithMessage, data) => {
    const resGetTicketData = await serviceGetTicketsById(cookies.SIDikey, lastMessage.ticketId);
    if (resGetTicketData) {
      const updateData = [...data];
      updateData[matchDataWithMessage].rating = resGetTicketData.data.data[0].rating;
      setData(updateData);
    }
  };

  const ticketUpdateNew = async () => {
    const newData = [];
    await Promise.all(
      ticketUpdate.map(async (ticket) => {
        if (!ticket) return false;
        if (roleFunctions.includes("Access_All_Dept")) {
          const getNewData = await getNewTicketById(ticket.id);
          if (getNewData) {
            newData.push(getNewData);
          }
        }
        if (roleFunctions.includes("Access_Own")) {
          // filter ticket berdasarkan department
          if (ticket.salesId === cookies.id) {
            const getNewData = await getNewTicketById(ticket.id);
            if (getNewData) {
              newData.push(getNewData);
            }
          }
          if (ticket.salesId === "3ef9f486-ddad-46ed-b568-f7b32a62262f") {
            // add new ticket later to sales
            setChatbotTicket([ticket.chatId, ...chatbotTicket]);
          }
        }
        if (roleFunctions.includes("Access_Own_Dept") && cookies.departmentIds.includes(ticket.departmentId)) {
          if (roleFunctions.includes("View_Chatbot")) {
            const getNewData = await getNewTicketById(ticket.id);
            if (getNewData) {
              newData.push(getNewData);
            }
          } else {
            if (ticket.salesId === "3ef9f486-ddad-46ed-b568-f7b32a62262f") {
              setChatbotTicket([ticket.chatId, ...chatbotTicket]);
            } else {
              const getNewData = await getNewTicketById(ticket.id);
              if (getNewData) {
                newData.push(getNewData);
              }
            }
          }
        }
      })
    );
    setData([...newData, ...data]);
  };

  const ticketUpdateBySales = async (salesArr) => {
    const ticketData = [...data];
    const newData = [];
    await Promise.all(
      salesArr.map(async (sales) => {
        if (!sales) return false;
        const matchDataWithMessage = ticketData.findIndex((ticket) => ticket.id === sales.ticket[0].id);
        if (matchDataWithMessage >= 0) {
          // message sesuai dengan chat list
          ticketData[matchDataWithMessage].user = sales.user;
        }

        if (roleFunctions.includes("Access_Own")) {
          // for sales
          if (sales.userId === cookies.id) {
            // new sales for this user
            if (matchDataWithMessage < 0) {
              const getNewData = await getNewTicketById(sales.ticket[0].id, ticketData);
              if (getNewData) {
                newData.push(getNewData);
              }
            }
          } else {
            // new sales is not for this user
            if (matchDataWithMessage >= 0) {
              ticketData.splice(matchDataWithMessage, 1);
            }
          }
        }
      })
    );
    setData([...newData, ...ticketData]);
  };

  const getNewTicket = async (chatId) => {
    const resGetTicketId = await serviceGetChatById(cookies.SIDikey, chatId);
    if (resGetTicketId.status === 200) {
      const resGetTicketData = await serviceGetTicketsById(cookies.SIDikey, resGetTicketId.data.lastMessage.ticketId);
      if (resGetTicketData.status === 200) {
        const findTicketOnData = data.findIndex((item) => item.id === resGetTicketData.data.data[0].id);
        if (findTicketOnData < 0) {
          setData([resGetTicketData.data.data[0], ...data]);
        }
      }
    }
  };

  const getNewTicketById = async (ticketId, ticketData = data) => {
    const resGetTicketData = await serviceGetTicketsById(cookies.SIDikey, ticketId);
    if (resGetTicketData.status === 200) {
      const findTicketOnData = ticketData.findIndex((item) => item.id === resGetTicketData.data.data[0].id);
      if (findTicketOnData < 0) {
        return resGetTicketData.data.data[0];
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    setLeadSourceOption([
      { value: "ALL", label: "ALL" },
      ...tagsData
        .filter((item) => item?.tagCategory?.name === "Lead Source")
        .map((item) => ({
          label: item?.name || "Unknown",
          value: item?.id || "unknown",
          category: item?.tagCategory ?? {},
        })),
    ]);

    setProspectOption([
      { value: "ALL", label: "ALL" },
      ...tagsData
        .filter((item) => item?.tagCategory?.name === "Status Prospect")
        .map((item) => ({
          label: item?.name || "Unknown",
          value: item?.id || "unknown",
          category: item?.tagCategory ?? {},
        })),
    ]);
  }, [tagsData]);

  useLayoutEffect(() => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
      });
      setNonFixedHeight(tempHeight);
    }
  }, [data]);

  const refreshTickets = async (pageNumber) => {
    const resp = await serviceGetTickets(cookies.SIDikey, roleFunctions, cookies.departmentIds, cookies.id, pageNumber);
    setMetaData(resp.data.meta);
    if (new RegExp(/20+/).test(resp.status)) {
      const ticketData = resp.data.data;
      setData(ticketData);
    } else {
    }
  };

  const roleFunctionsCampaign = JSON.parse(localStorage.getItem("permission"))["campaigns"].access || [];
  const refreshCampaign = async (page = 0, campaignOption) => {
    const resp = await serviceGetCampaigns(
      cookies.SIDikey,
      roleFunctionsCampaign,
      cookies.departmentIds,
      cookies.id,
      page + 1
    );
    if (new RegExp(/20+/).test(resp.status)) {
      const campaignData = resp.data.data;
      if(campaignOption && campaignOption.length > 0){
        setCampaignOption([
          ...campaignOption,
          ...campaignData.map((item) => ({ value: item.id, label: item.link.name })),
        ]);
      }else{
        setCampaignOption([
          ...campaignData.map((item) => ({ value: item.id, label: item.link.name })),
        ]);
      }
      if (resp.data.meta.hasNextPage) {
        setCampaignPage(page + 1);
      } else {
        setCampaignPage(-1);
      }
    } else {
      console.log(resp);
    }
  };

  const handleCampaignSelectScroll = () => {
    setRunGetCmapaign((getC) => getC + 1);
  };

  useEffect(() => {
    if (campaignPage >= 0) {
      refreshCampaign(campaignPage, campaignOption);
    }
  }, [runGetCampaign]);

  const refreshBusinessOpt = async () => {
    const resp = await getDepartmentsAll(cookies.SIDikey, ["Access_All_Dept"], "DESC", "department.createdAt");
    if (new RegExp(/20+/).test(resp.status)) {
      let departmentOptData = resp.data;
      if (!roleFunctions.includes("Access_All_Dept")) {
        departmentOptData = departmentOptData.filter((df) => cookies.departmentIds.some((dp) => df.id === dp));
      }
      setbusinessOption([
        { value: "ALL", label: "ALL" },
        ...departmentOptData?.map((item) => ({ label: item?.name, value: item.id })),
      ]);
    } else {
      console.log(resp);
    }
  };

  const refreshTicketsByQuery = async (query, pageNumber, field, departmentId) => {
    const sort = {
      field: sortKeyword,
      order: orderBy,
      queryRating,
      queryStatus,
      queryBusiness: queryBusiness,
    };

    const resp = await serviceGetTicketsByQuery(
      cookies.SIDikey,
      cookies.id,
      departmentId,
      query.replace(/,+/g, ","),
      roleFunctions,
      pageNumber,
      field,
      sort,
      queryStatus,
      queryRating
    );
    if (new RegExp(/20+/).test(resp.status)) {
      const ticketData = resp.data.data;
      const ticketIDs = ticketData.map((itm) => itm.id);
      if (field.includes("tags.name")) {
        const getTicketIDs = await serviceGetTicketByIds({
          token: cookies.SIDikey,
          ticketIDs,
        });
        if (new RegExp(/20+/).test(getTicketIDs.status)) {
          ticketData.forEach((itm) => {
            const tagsByTicket = getTicketIDs.data.data.find((ticket) => ticket.id === itm.id);
            itm.tags = tagsByTicket.tags;
          });
        }
      }
      setData(ticketData);
      setMetaData(resp.data.meta);
    } else {
      console.log(resp);
    }
  };

  const refreshTicketByField = async (field, pageNumber, order) => {
    const queryFilter = {
      queryText,
      fieldText,
      queryRating,
      queryStatus,
    };
    const resp = await serviceGetTicketsByField(
      cookies.SIDikey,
      cookies.id,
      cookies.departmentId,
      field,
      roleFunctions,
      pageNumber,
      order,
      queryFilter,
      queryStatus,
      queryRating
    );
    if (new RegExp(/20+/).test(resp.status)) {
      const ticketData = resp.data.data;
      setData(ticketData);
      setMetaData(resp.data.meta);
    } else {
      console.log(resp);
    }
  };

  const refreshTicketSummary = async () => {
    const resp = await serviceGetTicketSummary(cookies.SIDikey, cookies.departmentId, cookies.id, roleFunctions);
    const data = resp?.data;
    setSummaryData({
      opened: data?.OPENED,
      unopened: data?.TOTAL - data?.RESOLVED - data?.PENDING - data?.OPENED,
      resolved: data?.RESOLVED,
      pending: data?.PENDING,
    });
  };

  useMemo(() => {
    if (ticketStatusParam) {
      refreshTicketsByQuery(queryText, 1, fieldText, "");
    } else {
      refreshTickets(1);
    }

    refreshCampaign();
    refreshBusinessOpt();
    refreshTicketSummary();
  }, []);

  const onSelectChangeBusiness = (e) => {
    if (e.value !== "ALL") {
      setQueryBusiness(e.value);
      refreshTicketsByQuery(queryText, 1, fieldText, e.value);
    } else {
      setQueryBusiness("");
      refreshTickets(1, "");
    }
  };
  const onSelectChangeCampaign = (e) => {
    if (e.value !== "ALL") {
      setQueryCampaign("," + e.value);
      setFieldCampaign(",campaign.id");
    } else {
      setQueryCampaign("");
      setFieldCampaign("");
      refreshTickets(1);
    }
  };
  const onSelectChangeLeadSource = (e) => {
    if (e.value !== "ALL") {
      setQueryLeadSource("," + e.value);
      setFieldLeadSource(",tags.lead");
    } else {
      setQueryLeadSource("");
      setFieldLeadSource("");
      refreshTickets(1);
    }
  };
  const onSelectProspect = (e) => {
    if (e.value !== "ALL") {
      setQueryProspect("," + e.value);
      setFieldProspect(",tags.prospect");
    } else {
      setQueryProspect("");
      setFieldProspect("");
      refreshTickets(1);
    }
  };

  const onSelectStatus = (e) => {
    if (e.value !== "ALL") {
      setQueryStatus(e.label);
      setFieldStatus(",ticket.status");
    } else {
      setQueryStatus("");
      setFieldStatus("");
      refreshTickets(1);
    }
  };

  const onSelectRating = (e) => {
    if (e.value !== "ALL") {
      setQueryRating(e.label);
      setFieldRating(",ticket.rating");
    } else {
      setQueryRating("");
      setFieldRating("");
      refreshTickets(1);
    }
  };

  const onSortClicked = (id, order) => {
    setOrderBy(order);
    refreshTicketByField(id, 1, order);
    setIsSorting(true);
    setSortKeyword(id);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure for delete " + "" + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseDelete = await serviceDeleteTickets(id, cookies.SIDikey);
          if (responseDelete.status === 202) {
            Swal.fire("Berhasil Delete Ticket", "Delete Ticket", "success");
          } else {
            Swal.fire("Gagal Delete Ticket", responseDelete.message, "error");
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  // function to toggle the search option
  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data
    .slice(indexOfFirstItem, indexOfLastItem)
    .map((item) => ({ ...item, department: departmentData?.find((itm) => item.departmentId === itm.id) }));
  // Change Page
  const paginate = (pageNumber) => {
    const savedFilter = JSON.parse(localStorage.getItem("currentFilter"));
    if (isSorting) {
      if (
        (savedFilter.queryText !== "" && savedFilter.fieldText !== "") ||
        savedFilter.queryRating !== "" ||
        savedFilter.queryStatus !== "" ||
        savedFilter.queryBusiness !== ""
      ) {
        return refreshTicketsByQuery(
          savedFilter.queryText,
          pageNumber,
          savedFilter.fieldText,
          savedFilter.queryBusiness,
          savedFilter.orderBy,
          savedFilter.queryStatus,
          savedFilter.queryRating
        );
      } else {
        return refreshTicketByField(sortKeyword, pageNumber, orderBy);
      }
    } else {
      if (
        (savedFilter.queryText !== "" && savedFilter.fieldText !== "") ||
        savedFilter.queryRating !== "" ||
        savedFilter.queryStatus !== "" ||
        savedFilter.queryBusiness !== ""
      ) {
        return refreshTicketsByQuery(
          savedFilter.queryText,
          pageNumber,
          savedFilter.fieldText,
          savedFilter.queryBusiness,
          savedFilter.orderBy,
          savedFilter.queryStatus,
          savedFilter.queryRating
        );
      } else {
        return refreshTickets(pageNumber, queryBusiness);
      }
    }

    // if (isSorting) {
    //     return refreshTicketByField(sortKeyword, pageNumber, orderBy);
    // } else if ((isSorting) && (savedFilter.queryText !== "" && savedFilter.fieldText !== "") ||
    // savedFilter.queryRating !== "" || savedFilter.queryStatus !== "" || savedFilter.queryBusiness !== "") {
    //     return refreshTicketsByQuery(savedFilter.queryText,
    //       pageNumber,
    //       savedFilter.fieldText,
    //       savedFilter.queryBusiness,
    //       orderBy,
    //       savedFilter.queryStatus,
    //       savedFilter.queryRating);
    // } else {
    //     return refreshTickets(pageNumber, queryBusiness);
    // }
  };

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };

  const convertObjectToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const handleDownload = async () => {
    const resp = await serviceGetAllTickets(cookies.SIDikey);
    const tempData = resp.map((item) => {
      return {
        ...item,
        createdBy: item.createdBy?.firstName,
        updatedBy: item.updatedBy?.firstName,
        user: item.user?.firstName + " " + item.user?.lastName,
      };
    });

    const csv = convertObjectToCSV(tempData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `ticketing_page.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    const queryTextValue =
      queryId + queryName + queryContact + queryPhone + queryLeadSource + queryProspect + queryCampaign + queryPic;
    const fieldTextValue =
      fieldId + fieldName + fieldContact + fieldPhone + fieldLeadSource + fieldProspect + fieldCampaign + fieldPic;

    setQueryText(queryTextValue);
    setFieldText(fieldTextValue);
    setQueryBusiness(queryBusiness);
    setOrderBy(orderBy);

    // Simpan status filter dan sorting saat ini di localStorage
    const currentFilter = {
      orderBy: orderBy,
      queryText: queryTextValue,
      fieldText: fieldTextValue,
      queryRating,
      queryStatus,
      queryBusiness: queryBusiness,
    };

    localStorage.setItem("currentFilter", JSON.stringify(currentFilter));
  }, [
    orderBy,
    queryId,
    queryName,
    queryContact,
    queryPhone,
    queryLeadSource,
    queryProspect,
    queryCampaign,
    queryPic,
    queryStatus,
    queryRating,
    queryBusiness,
    fieldId,
    fieldName,
    fieldContact,
    fieldPhone,
    fieldLeadSource,
    fieldProspect,
    fieldCampaign,
    fieldPic,
    fieldRating,
    fieldStatus,
  ]);
  useEffect(() => {
    // Ambil status filter dan sorting dari localStorage
    const savedFilter = JSON.parse(localStorage.getItem("currentFilter"));

    if (
      (savedFilter.queryText !== "" && savedFilter.fieldText !== "") ||
      savedFilter.queryRating !== "" ||
      savedFilter.queryStatus !== "" ||
      savedFilter.queryBusiness !== ""
    ) {
      setonSearch(true);

      refreshTicketsByQuery(
        savedFilter.queryText,
        1,
        savedFilter.fieldText,
        savedFilter.queryBusiness,
        savedFilter.orderBy,
        savedFilter.queryStatus,
        savedFilter.queryRating
      );
    } else {
      setonSearch(false);
      refreshTickets(1);
    }
  }, [fieldText, queryText, queryRating, queryStatus]);

  const handleClearPlus = () => {
    setShowPlus(false);
  };

  return (
    <React.Fragment>
      <Head title="Ticket - Compact"></Head>
      <Content>
        <BlockHead size="sm" className="pl-4">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Ticketing
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                {!roleFunctions.includes("download") || (
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li>
                        <a
                          href="#export"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleDownload();
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Download</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable>
            <Row>
              <Col xs="5" sm="4" md="4" lg="3" className="fixed-column ">
                <DataTableBody compact>
                  <SimpleBar className="ticket-simplebar">
                    <DataTableHead>
                      <DataTableRow>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 150 }}
                        >
                          Ticket ID{" "}
                          <Button size="xs" id="ticketId">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="ticketId">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.id", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.id", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 150 }}
                        >
                          Ticket Name
                          <Button id="ticketName" size="xs">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="ticketName">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.name", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.name", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableHead>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                          {/* <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div> */}
                          {/* <input
                            disabled={activeSearch !== "id-search" && activeSearch !== ""}
                            onChange={(e) => {
                              setQueryId("," + e.target.value);
                              setFieldId(",ticket.id");
                              if (e.target.value === "") {
                                let fieldN = fieldId;
                                setFieldContact(fieldN.replace(/ticket\\.id/g, ""));
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                            type="text"
                            className="form-control w-100"
                            id="id-search"
                          /> */}
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1" style={{ width: 150 }}>
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            disabled={activeSearch !== "name-search" && activeSearch !== ""}
                            onBlur={() => setActiveSearch("")}
                            onChange={(e) => {
                              setQueryName("," + e.target.value);
                              setFieldName(",ticket.name");
                              if (e.target.value === "") {
                                let fieldN = fieldName;
                                setFieldName(fieldN.replace(",ticket.name", ""));
                              }
                            }}
                            type="text"
                            className="form-control w-100"
                            id="name-search"
                          />
                        </div>
                      </DataTableRow>
                    </DataTableHead>
                    {/*Head*/}
                    {currentItems.map((item, idx) => {
                      return (
                        <DataTableItem key={item.id}>
                          <DataTableRow
                            noWrap
                            customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[idx] } : {}}
                            className="sm"
                          >
                            {item.id.substring(0, 10)}
                          </DataTableRow>
                          <DataTableRow noWrap>
                            {item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name}
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })}
                  </SimpleBar>
                </DataTableBody>
              </Col>
              <Col xs="5" sm="8" md="8" lg="9" className="nfixed-column">
                <DataTableBody compact>
                  <SimpleBar className="ticket-simplebar">
                    <DataTableHead>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">
                          Customer Name
                          <Button size="xs" id="contactName">
                            <Icon name="sort" />
                          </Button>
                          <UncontrolledTooltip autohide={false} placement="top" target="contactName">
                            <div className="tooltip-ticket-sorting">
                              <Button
                                color={orderBy === "ASC" ? "primary" : "secondary"}
                                onClick={() => onSortClicked("contact.name", "ASC")}
                                size="xs"
                              >
                                ASC
                              </Button>
                              <Button
                                color={orderBy === "DESC" ? "primary" : "secondary"}
                                onClick={() => onSortClicked("contact.name", "DESC")}
                                size="xs"
                              >
                                DESC
                              </Button>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">
                          Number Telephone
                          <Button size="xs" id="chatCustomerPhone">
                            <Icon name="sort" />
                          </Button>
                          <UncontrolledTooltip autohide={false} placement="top" target="chatCustomerPhone">
                            <div className="tooltip-ticket-sorting">
                              <Button
                                color={orderBy === "ASC" ? "primary" : "secondary"}
                                onClick={() => onSortClicked("chat.customerPhone", "ASC")}
                                size="xs"
                              >
                                ASC
                              </Button>
                              <Button
                                color={orderBy === "DESC" ? "primary" : "secondary"}
                                onClick={() => onSortClicked("chat.customerPhone", "DESC")}
                                size="xs"
                              >
                                DESC
                              </Button>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          style={{ width: "150px" }}
                          className="sub-text d-flex justify-content-between align-items-center"
                        >
                          Business
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 200 }}
                        >
                          Lead Source
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 200 }}
                        >
                          Prospect
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 180 }}
                        >
                          Campaign
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 150 }}
                        >
                          Status
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 200 }}
                        >
                          PIC
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 80 }}
                        >
                          Rating
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div
                          className="sub-text d-flex justify-content-between align-items-center"
                          style={{ width: 80 }}
                        >
                          Rate At
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">Action</div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">
                          Created at
                          <Button size="xs" id="createdAt">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="createdAt">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.createdAt", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.createdAt", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">Created by</div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">
                          Modified at
                          <Button size="xs" id="updatedAt">
                            <Icon name="sort" />
                          </Button>
                        </div>
                        <UncontrolledTooltip autohide={false} placement="top" target="updatedAt">
                          <div className="tooltip-ticket-sorting">
                            <Button
                              color={orderBy === "ASC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.updatedAt", "ASC")}
                              size="xs"
                            >
                              ASC
                            </Button>
                            <Button
                              color={orderBy === "DESC" ? "primary" : "secondary"}
                              onClick={() => onSortClicked("ticket.updatedAt", "DESC")}
                              size="xs"
                            >
                              DESC
                            </Button>
                          </div>
                        </UncontrolledTooltip>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <div className="sub-text d-flex justify-content-between align-items-center">Modified by</div>
                      </DataTableRow>
                    </DataTableHead>
                    {/*Head*/}
                    <DataTableHead>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            disabled={activeSearch !== "customer-search" && activeSearch !== ""}
                            id="customer-search"
                            type="text"
                            className="form-control w-100"
                            onChange={(e) => {
                              setQueryContact("," + e.target.value);
                              setFieldContact(",contact.name");
                              // setQueryPhone("," + e.target.value);
                              // setFieldPhone(",chat.customerPhone");
                              if (e.target.value === "") {
                                setFieldContact("");
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                          />
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            type="text"
                            className="form-control w-100"
                            disabled={activeSearch !== "customer-phone-search" && activeSearch !== ""}
                            onChange={(e) => {
                              setQueryPhone("," + e.target.value);
                              setFieldPhone(",chat.customerPhone");
                              if (e.target.value === "") {
                                // let fieldN = fieldPhone;
                                // setFieldPhone(fieldN.replace(/chat\\.customerPhone/g, ""));
                                setFieldPhone("");
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                            id="customer-phone-search"
                          />
                          {queryPhone && showPlus && (
                            <span
                              style={{
                                position: "absolute",
                                left: "5px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                              onClick={handleClearPlus}
                            >
                              +
                            </span>
                          )}
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <RSelect
                          onBlur={() => setOnSearchText("")}
                          onChange={onSelectChangeBusiness}
                          options={businessOption}
                        />
                      </DataTableRow>
                      <DataTableRow>
                        <RSelect
                          onChange={onSelectChangeLeadSource}
                          components={(e) => CampaignBadge(e.label)}
                          options={leadSourceOption}
                        />
                      </DataTableRow>
                      <DataTableRow>
                        <RSelect
                          onChange={onSelectProspect}
                          components={(e) => CampaignBadge(e.label)}
                          options={prospectOption}
                        />
                      </DataTableRow>
                      <DataTableRow>
                        <RSelect
                          onChange={onSelectChangeCampaign}
                          options={campaignOption}
                          onMenuScrollToBottom={handleCampaignSelectScroll}
                        />
                      </DataTableRow>
                      <DataTableRow>
                        <RSelect onChange={onSelectStatus} options={statusOption} />
                      </DataTableRow>
                      <DataTableRow>
                        <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input
                            type="text"
                            className="form-control w-100"
                            disabled={activeSearch !== "pic-search" && activeSearch !== ""}
                            onChange={(e) => {
                              setQueryPic("," + e.target.value);
                              setFieldPic(",user.firstName");
                              if (e.target.value === "") {
                                let fieldN = fieldPic;
                                setFieldPic(fieldN.replace(/user\\.firstName/g, ""));
                              }
                            }}
                            onBlur={() => setActiveSearch("")}
                            id="pic-search"
                          />
                        </div>
                      </DataTableRow>
                      <DataTableRow noWrap>
                        <RSelect onChange={onSelectRating} options={ratingOption} />
                      </DataTableRow>
                      <DataTableRow>
                        {/* <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input type="text" className="form-control w-100" id="default-04" />
                        </div> */}
                      </DataTableRow>
                      <DataTableRow>
                        {/* <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input type="text" className="form-control w-100" id="default-04" />
                        </div> */}
                      </DataTableRow>
                      <DataTableRow>
                        {/* <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input type="text" className="form-control w-100" id="default-04" />
                        </div> */}
                      </DataTableRow>
                      <DataTableRow>
                        {/* <div className="form-control-wrap mt-1">
                          <div className="form-icon form-icon-right">
                            <Icon name="search"></Icon>
                          </div>
                          <input type="text" className="form-control w-100" id="default-04" />
                        </div> */}
                      </DataTableRow>
                    </DataTableHead>
                    {currentItems.map((item, idx) => {
                      return (
                        <DataTableItem key={item.id}>
                          <DataTableRow size="md" ref={addToRefs}>
                            {/* {item.chat?.contact.isSaved ? item.chat?.contact.name : `+${item.chat?.customerPhone}`} */}
                            {item.chat?.name ?? item.chat?.customerPhone}
                          </DataTableRow>
                          <DataTableRow>{utilNumberPhoneConvention(item.chat?.customerPhone)}</DataTableRow>
                          <DataTableRow>
                            <span
                              className="badge rounded-pill text-bg-primary"
                              style={{
                                background: item.department?.color ? item.department.color : "#fffff",
                                borderColor: item.department?.color ? item.department.color : "#fffff",
                                color: getTextColor(item.department?.color ? item.department.color : "#fffff"),
                              }}
                            >
                              {item.department?.name}
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <div
                              className="badge rounded-pill text-bg-primary"
                              style={{
                                background: `${
                                  item?.tags?.find((tag) => tag?.tagCategory?.name === "Lead Source")?.color ?? "black"
                                }`,
                                color: "white",
                              }}
                            >
                              {item?.tags?.find((tag) => tag?.tagCategory?.name === "Lead Source")?.name ?? ""}
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div
                              className="badge rounded-pill text-bg-primary"
                              style={{
                                background: `${
                                  item?.tags?.find((tag) => tag?.tagCategory?.name === "Status Prospect")?.color ??
                                  "black"
                                }`,
                                color: "white",
                              }}
                            >
                              {item?.tags?.find((tag) => tag?.tagCategory?.name === "Status Prospect")?.name ?? ""}
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            {CampaignBadge(
                              item?.campaign?.shortUrl
                                ?.replace("https://astronacci.org/", "")
                                .replace("https://url.ismartglobal.id/", "") || "-",
                              item
                            )}
                          </DataTableRow>
                          <DataTableRow>
                            {item.user?.firstName === "Chatbot" ? (
                              "Chatbot"
                            ) : (
                              <Badge
                                color={`outline-${
                                  item?.status === "RESOLVED"
                                    ? "success"
                                    : item?.status === "OPENED"
                                    ? "primary"
                                    : item?.status === "PENDING"
                                    ? "warning"
                                    : "danger"
                                }`}
                                pill
                              >
                                {item?.status === null ? "UNOPENED" : item.status}
                              </Badge>
                            )}
                          </DataTableRow>
                          <DataTableRow className="font-italic">
                            {item.user?.firstName ? item.user?.firstName + " " + item.user?.lastName : "-"}
                          </DataTableRow>
                          <DataTableRow className="font-italic">{item?.rating ? item?.rating : "0"}</DataTableRow>
                          <DataTableRow className="font-italic">
                            {item?.ratedAt ? moment(item?.ratedAt).format("MMM DD, YYYY, h:mm") : "-"}
                          </DataTableRow>
                          <DataTableRow>
                            <ul className="table-action">
                              <li className="nk-tb-action">
                                <Link
                                  id={"detail" + item.id}
                                  to={{
                                    pathname: process.env.PUBLIC_URL + "/ticket/detail",
                                    state: {
                                      ticket: item,
                                      edit: false,
                                      picOptions: [],
                                    },
                                  }}
                                  className="btn btn-trigger btn-icon"
                                >
                                  <Icon name="eye-fill" />
                                </Link>
                                <UncontrolledTooltip autohide={false} placement="top" target={"detail" + item.id}>
                                  Detail
                                </UncontrolledTooltip>
                              </li>
                              {!roleFunctions.includes("update") || (
                                <li className="nk-tb-action">
                                  <Link
                                    id={"edit" + item.id}
                                    to={{
                                      pathname: process.env.PUBLIC_URL + "/ticket/detail",
                                      state: {
                                        ticket: item,
                                        edit: true,
                                        picOptions: [],
                                      },
                                    }}
                                    className="btn btn-trigger btn-icon"
                                  >
                                    <Icon name="edit-alt-fill" />
                                  </Link>
                                  <UncontrolledTooltip autohide={false} placement="top" target={"edit" + item.id}>
                                    Edit
                                  </UncontrolledTooltip>
                                </li>
                              )}
                              {/* {!roleFunctions.includes("delete") || (
                                <li
                                  className="nk-tb-action"
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"delete" + item.id}
                                    icon="trash-fill"
                                    direction="top"
                                    text="Hapus"
                                  />
                                </li>
                              )} */}
                            </ul>
                          </DataTableRow>
                          <DataTableRow noWrap>{moment(item.createdAt).format("MMM DD, YYYY, h:mm")}</DataTableRow>
                          <DataTableRow noWrap>
                            {item.createdBy ? item.createdBy.firstName + " " + item.createdBy?.lastName : ""}
                          </DataTableRow>
                          <DataTableRow noWrap>{moment(item.updatedAt).format("MMM DD, YYYY, h:mm")}</DataTableRow>
                          <DataTableRow noWrap>
                            {item.updatedBy ? item.updatedBy?.firstName + " " + item.updatedBy?.lastName : ""}
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })}
                  </SimpleBar>
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
            <div className="pt-4 mb-2 pl-4 border-top">
              <BlockTitle tag="h3" page>
                Status Summary
              </BlockTitle>
            </div>
            <div className="d-flex text-center mb-5">
              <div className="border-right px-5 py-4 ">
                <BlockTitle tag="h2" className="">
                  {summaryData.unopened}
                </BlockTitle>
                <div
                  style={{ fontSize: "18px" }}
                  className="d-flex justify-content-center align-items-center mt-2 text-danger"
                >
                  <Icon name="cross" />
                  <p className="ml-1"> Unopened</p>
                </div>
              </div>
              <div className="border-right px-5 py-4 ">
                <BlockTitle tag="h2" className="">
                  {summaryData.opened}
                </BlockTitle>
                <div
                  style={{ fontSize: "18px" }}
                  className="d-flex justify-content-center align-items-center mt-2 text-primary"
                >
                  <Icon name="clock" />
                  <p className="ml-1"> Opened</p>
                </div>
              </div>
              <div className="px-5 py-4  border-right " style={{ fontSize: "18px" }}>
                <BlockTitle tag="h2" className="">
                  {summaryData.pending}
                </BlockTitle>
                <div className="d-flex justify-content-center align-items-center mt-2 text-warning">
                  <Icon name="loader" />
                  <p className="ml-1"> Pending</p>
                </div>
              </div>
              <div className="px-5 py-4  " style={{ fontSize: "18px" }}>
                <BlockTitle tag="h2" className="">
                  {summaryData.resolved}
                </BlockTitle>
                <div className="d-flex justify-content-center align-items-center mt-2 text-success">
                  <Icon name="check" />
                  <p className="ml-1"> Resolved</p>
                </div>
              </div>
            </div>
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Ticket;
