import React, { useState, useEffect, useContext, useRef } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { userData } from "../pre-built/user-manage/UserData";
import { FormGroup, Modal, ModalBody, Form, Card, CardBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "../pre-built/user-manage/UserContext";
import { useCookies } from "react-cookie";
import getTextColor from "../../components/custom/getTextColor";
import Swal from "sweetalert2";
import {
  serviceDeleteUsers,
  serviceGetUsers,
  serviceGetUsersAndOrder,
  servicePostUsers,
  servicePutUsers,
} from "../../services/users";
import { getDepartments } from "../../services/departments";
import { getCountries } from "../../services/countries";
import moment from "moment";
import { serviceGetListSodRole } from "services/sod-role";
import { UncontrolledTooltip } from "reactstrap";

const Users = () => {
  const nonFixedRef = useRef([]);
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const [cookies] = useCookies(["user"]);

  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;

  const [sm, updateSm] = useState(false);
  const [passState, setPassState] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });
  const [editContent, setEditContent] = useState(false);
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    roleId: "",
    role: "",
    email: "",
    avatar: "",
    phone: "",
    // department: {
    //   id: "",
    //   name: "",
    //   color: "",
    // },
    department: [],
    active: true,
  });
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 0,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [isSorting, setIsSorting] = useState(false);
  const [sortKeyword, setSortKeyword] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [orderBy, setOrderBy] = useState("DESC");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [opsiRole, setOpsiRole] = useState([
    { value: "SUPER ADMIN", label: "SUPER ADMIN" },
    { value: "HEAD", label: "HEAD" },
    { value: "ADMIN", label: "ADMIN" },
    { value: "SALES", label: "SALES" },
    { value: "CS", label: "CS" },
    { value: "AUDITOR", label: "AUDITOR" },
    { value: "SYSTEM ADMIN", label: "SYSTEM ADMIN" },
  ]);

  const [opsiDepartement, setOpsiDepartement] = useState([]);
  const [opsiCountries, setOpsiCountries] = useState([]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["users"].access || [];

  const roleSelected = (e) => {
    const temp = formData;
    temp.roleId = e.value;
    temp.role = e.label;

    setFormData(temp);
  };

  const refresHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
        return height;
      });
      setNonFixedHeight(tempHeight);
    }
  };

  const refreshOpsiDepartement = async () => {
    const refreshGetDepartements = await getDepartments(
      cookies.SIDikey,
      "",
      cookies.departmentIds,
      cookies.id,
      roleFunctions
    );
    if (new RegExp(/20+/).test(refreshGetDepartements.status)) {
      const resultData = refreshGetDepartements.data.data;
      const finalData = [];
      for (let departement of resultData) {
        const tempData = {
          value: departement.id,
          label: departement.name,
          color: departement.color,
        };
        finalData.push(tempData);
      }
      setOpsiDepartement(finalData);
    } else {
      console.log(refreshGetDepartements);
    }
  };

  const refreshOpsiRole = async () => {
    const resp = await serviceGetListSodRole(cookies.SIDikey);

    if (new RegExp(/20+/).test(resp.status)) {
      const resultData = resp.data;
      setOpsiRole(
        resultData.map((dt) => {
          return { value: dt.id, label: dt.name };
        })
      );
    } else {
      console.log(resp);
    }
  };

  const refreshOpsiCountries = async () => {
    const responseGetCountries = await getCountries(cookies.SIDikey);
    const resultData = responseGetCountries.data;
    const finalData = [];
    for (let countries of resultData) {
      const tempData = {
        value: countries.phonePrefix,
        label: (
          <div>
            <img src={countries.flag} style={{ width: 25 }} alt={countries.name} /> {countries.phonePrefix}
          </div>
        ),
      };
      finalData.push(tempData);
    }
    setOpsiCountries(finalData);
  };

  const refreshData = async (pageNumber, userPerPage) => {
    if (!roleFunctions.includes("read")) {
      return;
    }
    const currentUserPerPage = userPerPage ? userPerPage : itemPerPage;
    const responseGetUsers = await serviceGetUsers(
      cookies.SIDikey,
      roleFunctions,
      // cookies.departmentId,
      cookies.departmentIds,
      pageNumber,
      currentUserPerPage,
      onSearchText,
      cookies.id
    );

    setMetaData(responseGetUsers.data.meta);
    if (new RegExp(/20+/).test(responseGetUsers.status)) {
      const resultData = responseGetUsers.data.data || [responseGetUsers.data];
      const finalData = [];

      for (const user of resultData) {
        let createdBy = "";
        let updatedBy = "";

        if (user.createdBy != null) {
          createdBy = user.createdBy.firstName + " " + user.createdBy.lastName;
        } else {
          createdBy = "-";
        }

        if (user.updatedBy != null) {
          updatedBy = user.updatedBy.firstName + " " + user.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }

        const tempData = {
          id: user.id,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
          createdBy: createdBy,
          updatedBy: updatedBy,
          firstName: user.firstName,
          lastName: user.lastName,
          roleId: user.roleId,
          role: user.role,
          email: user.email,
          phone: user.phone,
          phonePrefix: user.phonePrefix,
          // department: {
          //   id: user.department?.id || "53237a91-3fc4-4e1a-8b05-cd3259ab57fa",
          //   name: user.department?.name || "ASHOP",
          //   color: user.department?.color || "#fffff",
          // },
          department: user.departments,
          active: user.isActive,
        };
        finalData.push(tempData);
      }

      setData(finalData);
      refresHeight();
    } else {
      console.log(responseGetUsers);
    }
  };
  const refreshDataByField = async (field, pageNumber, userPerPage, order) => {
    if (!roleFunctions.includes("read")) {
      return;
    }
    const currentUserPerPage = userPerPage ? userPerPage : itemPerPage;

    // const responseGetUsers = await serviceGetUsersAndOrder(
    //   cookies.SIDikey,
    //   roleFunctions,
    //   cookies.departmentId,
    //   pageNumber,
    //   currentUserPerPage,
    //   onSearchText,
    //   field,
    //   order
    // );

    const responseGetUsers = await serviceGetUsersAndOrder(
      cookies.SIDikey,
      roleFunctions,
      // cookies.departmentId,
      cookies.departmentIds,
      pageNumber,
      currentUserPerPage,
      onSearchText,
      cookies.id,
      field,
      order
    );

    setMetaData(responseGetUsers.data.meta);
    if (new RegExp(/20+/).test(responseGetUsers.status)) {
      const resultData = responseGetUsers.data.data || [responseGetUsers.data];
      const finalData = [];
      resultData.forEach((user) => {
        let createdBy = "";
        let updatedBy = "";

        if (user.createdBy != null) {
          createdBy = user.createdBy.firstName + " " + user.createdBy.lastName;
        } else {
          createdBy = "-";
        }

        if (user.updatedBy != null) {
          updatedBy = user.updatedBy.firstName + " " + user.updatedBy.lastName;
        } else {
          updatedBy = "-";
        }

        const tempData = {
          id: user.id,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
          createdBy: createdBy,
          updatedBy: updatedBy,
          firstName: user.firstName,
          lastName: user.lastName,
          roleId: user.roleId,
          role: user.role,
          email: user.email,
          phonePrefix: user.phonePrefix,
          phone: user.phone,
          // department: {
          //   id: user.department?.id || "53237a91-3fc4-4e1a-8b05-cd3259ab57fa",
          //   name: user.department?.name || "ASHOP",
          //   color: user.department?.color || "#fffff",
          // },
          department: user.departments,
          active: user.isActive,
        };
        finalData.push(tempData);
      });

      setData(finalData);
      refresHeight();
    } else {
      console.log(responseGetUsers);
    }
  };
  // unselects the data on mount
  useEffect(() => {
    refreshOpsiDepartement();
    refreshOpsiRole();
    refreshOpsiCountries();
    refreshData(1, itemPerPage);
    // refreshChannels();
    let newData;
    newData = userData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, [onSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = userData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...userData]);
    }
  }, [onSearchText, setData]);

  // function to reset the form
  const resetForm = () => {
    setPhoneValue("");
    setFormData({
      id: "",
      firstName: "",
      lastName: "",
      roleId: "",
      role: "",
      email: "",
      avatar: "",
      phonePrefix: "",
      phone: "",
      // department: {
      //   id: "",
      //   name: "",
      //   color: "",
      // },
      department: [],
      active: true,
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ add: false, detail: false });
    setConfirmState(false);
    setPassState(false);
    setEditContent(false);
    resetForm();
  };

  const onFormSubmit = async (submitData) => {
    if (!roleFunctions.includes("create")) {
      return;
    }
    const { firstName, lastName, email, password, confirmPassword, isActive } = submitData;
    const deptIds = formData.department.map((item) => item.id);

    if (deptIds.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Choose Department",
        focusConfirm: false,
      });
    }

    if (
      password === confirmPassword &&
      formData.role !== "" &&
      formData.department.length > 0 &&
      formData.phone.length <= 13 &&
      formData.phone.length >= 9
    ) {
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phonePrefix: formData?.phonePrefix?.value ? formData.phonePrefix.value : "+62",
        phone: formData.phone,
        roleId: formData.roleId,
        // role: formData.role,
        password: password,
        departmentIds: deptIds,
        isActive: isActive ? true : false,
      };

      if (data.phone.slice(0, 2) === "08") {
        data.phone = formData?.phonePrefix?.value ? formData.phonePrefix.value : "+62" + data.phone.slice(1);
      } else {
        data.phone = formData?.phonePrefix?.value ? formData.phonePrefix.value : "+62" + data.phone;
      }
      // if (data.phone.slice(0, 2) === "08") {
      //   data.phone = "+62" + data.phone.slice(1);
      // } else if (data.phone.slice(0, 1) === "8") {
      //   data.phone = "+62" + data.phone;
      // } else if (data.phone.slice(0, 2) === "62") {
      //   data.phone = "+" + data.phone;
      // }
      // data.phone = data.phone.replace("++", "+");

      const responsePostUser = await servicePostUsers(cookies.SIDikey, data);
      if (new RegExp(/20+/).test(responsePostUser.status)) {
        Swal.fire({
          icon: "success",
          title: "New Added Succesfull",
          focusConfirm: false,
        }).then(() => {
          refreshData(1, itemPerPage);
          resetForm();
          setModal({ detail: false }, { add: false });
          setEditContent(false);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: responsePostUser,
          focusConfirm: false,
        });
      }
    }
  };

  // submit function to update a new item
  const onEditSubmit = async (submitData) => {
    if (!roleFunctions.includes("update")) {
      return;
    }
    const { firstName, lastName, email } = submitData;
    let deptIds = formData.department.map((department) => department.value);

    if(deptIds.some((di)=>di == null)){
      deptIds = [...selectedDepartment];
    }


    if (deptIds.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Choose Department",
        focusConfirm: false,
      });
    }

    if (formData.phone.length <= 13 && formData.phone.length >= 9) {
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        // phone: "+" + formData.phone,
        phonePrefix: formData.phonePrefix.value,
        phone: formData.phone,
        roleId: formData.roleId,
        departmentIds: deptIds,
        isActive: formData.active,
      };

      if (data.phone.slice(0, 2) === "08") {
        data.phone = formData.phonePrefix.value + data.phone.slice(1);
      } else {
        data.phone = formData.phonePrefix.value + data.phone;
      }
      // if (data.phone.slice(0, 2) === "08") {
      //   data.phone = "+62" + data.phone.slice(1);
      // } else if (data.phone.slice(0, 1) === "8") {
      //   data.phone = "+62" + data.phone;
      // } else if (data.phone.slice(0, 2) === "62") {
      //   data.phone = "+" + data.phone;
      // }
      // data.phone = data.phone.replace("++", "+");

      const responsePutUser = await servicePutUsers(formData.id, cookies.SIDikey, data);

      if (new RegExp(/20+/).test(responsePutUser.status)) {
        Swal.fire({
          icon: "success",
          title: "Updated Succesfull",
          focusConfirm: false,
        }).then(() => {
          refreshData(1, itemPerPage);
          resetForm();
          setModal({ detail: false }, { add: false });
          setEditContent(false);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: responsePutUser,
          focusConfirm: false,
        });
      }
    }
  };

  const onDeleteClick = (id, name) => {
    if (!roleFunctions.includes("delete")) {
      return;
    }
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDeleteUser = await serviceDeleteUsers(id, cookies.SIDikey);

        if (new RegExp(/20+/).test(responseDeleteUser.status)) {
          Swal.fire({
            icon: "success",
            title: "Succesfull Transasction",
            focusConfirm: false,
          }).then(() => {
            refreshData(1, itemPerPage);
            resetForm();
            setModal({ detail: false }, { add: false });
            setEditContent(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              responseDeleteUser.status === 400
                ? responseDeleteUser.data?.message === "error.userInUse"
                  ? "User masih terdaftar di rotator"
                  : responseDeleteUser.data?.message
                : responseDeleteUser.status === 409
                ? responseDeleteUser.data?.message[0].message
                : "Unsuccesfull Transasction",
            focusConfirm: false,
          });
        }
      }
    });
  };

  const onDetailClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setFormData({
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          role: item.role.name,
          roleId: item.role.id,
          email: item.email,
          avatar: item.avatar,
          phone: item.phone.replace(item.phonePrefix, ""),
          phonePrefix: opsiCountries.find((res) => res.value === item.phonePrefix),
          department: item.department.map((item) => ({
            color: item.color,
            value: item.id,
            label: item.name,
          })),
          active: item.active,
        });
        setModal({ detail: true, add: false });
        setEditedId(id);
        setSelectedDepartment(item.department.map((item) => item.id));
      }
    });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  const departementSelected = (e) => {
    const selectedValues = e.map((item) => ({ id: item.value || item.id }));
    setSelectedDepartment(e.map((item) => item.id || item.value));

    setFormData((prevFormData) => ({
      ...prevFormData,
      department: selectedValues,
    }));
  };

  const filterOptions = (options, selected) => {
    return options.filter((option) => !selected.includes(option.value || option.id));
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  const { errors, register, handleSubmit, getValues, setValue, watch } = useForm();

  const onSortClicked = (id, order) => {
    setOrderBy(order);
    refreshDataByField(id, 1, 10, order);
    setIsSorting(true);
    setSortKeyword(id);
  };
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber, itemPerPage) => {
    if (isSorting) {
      refreshDataByField(sortKeyword, pageNumber, itemPerPage, orderBy);
    } else {
      refreshData(pageNumber, itemPerPage);
    }
  };
  // const sortedItems = currentItems?.sort((a, b) => new moment(b.updatedAt) - new moment(a.updatedAt));

  const handleChangeActive = (val) => {
    const temp = formData;
    temp.active = val.target.checked;
    setFormData(temp);
  };

  let page = metaData?.page ?? 1;
  let take = metaData?.take ?? 1;

  return (
    <React.Fragment>
      <Head title="User"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                User
              </BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>You have total 2,595 users.</p>
              </BlockDes> */}
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>Make additional admins by connecting the channel that have been registered</p>
            </CardBody>
          </Card>

          <Block className="d-flex justify-content-end">
            <BlockHeadContent style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="form-control-wrap ">
                    <div className="form-icon form-icon-left">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      type="text"
                      name="search"
                      className="form-control w-100"
                      id="default-04"
                      placeholder="Search"
                      onInput={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                        <a
                          href="#export"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-white btn-outline-light"
                        >
                          <Icon name="download-cloud"></Icon>
                          <span>Export</span>
                        </a>
                      </li> */}

                    {!roleFunctions.includes("assign") || (
                      <li className="nk-block-tools-opt">
                        <Link className="btn btn-outlined-primary" to={`${process.env.PUBLIC_URL + "users/relasi"}`}>
                          <Button color="primary" className="btn btn-outlined" outline>
                            Lihat Relasi User
                          </Button>
                        </Link>
                      </li>
                    )}
                    {!roleFunctions.includes("create") || (
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                          <i className="ni ni-plus"></i>
                          <span>Tambah Baru</span>
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch" style={{ borderRadius: "10px" }}>
            <Row>
              <Col md="3" lg="3" className="fixed-column border-right">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Nama
                        <Button id="userName" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="userName">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.firstName", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.firstName", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems && currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow noWrap>
                              <span>{(page - 1) * take + (index + 1)}</span>
                            </DataTableRow>
                            <DataTableRow
                              noWrap
                              customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                            >
                              <span>{item.firstName + " " + item.lastName}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
              <Col md="9" lg="9" className="nfixed-column">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <div
                        className="sub-text d-flex justify-content-between align-items-center"
                        style={{ width: 150 }}
                      >
                        Email
                        <Button id="userEmail" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="userEmail">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.email", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.email", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        No Handphone
                        <Button id="userPhone" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="userPhone">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.phone", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.phone", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Hak Akses
                        <Button id="userRole" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="userRole">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("role.name", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("role.name", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div className="sub-text d-flex justify-content-between align-items-center">
                        Department
                        {/* <Button id="userDept" size="xs">
                          <Icon name="sort" />
                        </Button> */}
                      </div>
                      {/* <UncontrolledTooltip autohide={false} placement="top" target="userDept">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.name", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("department.name", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip> */}
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Aktif</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Aksi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        style={{ width: 150 }}
                        className="sub-text d-flex justify-content-between align-items-center"
                      >
                        Modify At
                        <Button id="userModifyAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="userModifyAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.updatedAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.updatedAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        style={{ width: 150 }}
                        className="sub-text d-flex justify-content-between align-items-center"
                      >
                        Modify By
                      </div>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        style={{ width: 150 }}
                        className="sub-text d-flex justify-content-between align-items-center"
                      >
                        Created At
                        <Button id="userCreatedAt" size="xs">
                          <Icon name="sort" />
                        </Button>
                      </div>
                      <UncontrolledTooltip autohide={false} placement="top" target="userCreatedAt">
                        <div className="tooltip-ticket-sorting">
                          <Button
                            color={orderBy === "ASC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.createdAt", "ASC")}
                            size="xs"
                          >
                            ASC
                          </Button>
                          <Button
                            color={orderBy === "DESC" ? "primary" : "secondary"}
                            onClick={() => onSortClicked("user.createdAt", "DESC")}
                            size="xs"
                          >
                            DESC
                          </Button>
                        </div>
                      </UncontrolledTooltip>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <div
                        style={{ width: 150 }}
                        className="sub-text d-flex justify-content-between align-items-center"
                      >
                        Created By
                      </div>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow noWrap>
                              <span>{item.email}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.phone}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.role.name}</span>
                            </DataTableRow>
                            <DataTableRow ref={addToRefs}>
                              {item?.department?.map((res) => (
                                <span
                                  className="badge rounded-pill text-bg-primary"
                                  style={{
                                    background: res.color,
                                    borderColor: res.color,
                                    color: getTextColor(res.color),
                                  }}
                                >
                                  {res.name}
                                </span>
                              ))}
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <ul className="list-status">
                                <li>
                                  <Icon
                                    className={`table-icon text-${item.active ? "success" : "warning"}`}
                                    name={item.active ? "check-circle" : "cross-circle"}
                                  ></Icon>
                                </li>
                              </ul>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <ul className="nk-tb-actions table-action">
                                <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"detail" + item.id}
                                    icon="eye-fill"
                                    direction="top"
                                    text="Detail"
                                  />
                                </li>
                                {roleFunctions.includes("update") && (
                                  <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"edit" + item.id}
                                      icon="edit-alt-fill"
                                      direction="top"
                                      text="Edit"
                                    />
                                  </li>
                                )}
                                {roleFunctions.includes("delete") && (
                                  <li
                                    className="nk-tb-action"
                                    onClick={() => onDeleteClick(item.id, item.firstName + " " + item.lastName)}
                                  >
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"suspend" + item.id}
                                      icon="trash-fill"
                                      direction="top"
                                      text="Hapus"
                                    />
                                  </li>
                                )}
                              </ul>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.updatedAt).format("MMM DD, YYYY, h:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.updatedBy}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.createdAt).format("MMM DD, YYYY, h:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.createdBy}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take ?? 1}
                  totalItems={metaData?.itemCount ?? 1}
                  paginate={paginate}
                  currentPage={metaData?.page ?? 1}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal
          isOpen={modal.add}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ add: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Tambah User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama Depan</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        defaultValue={formData.firstName}
                        placeholder="Masukkan Nama Admin"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.firstName && <span className="custom-invalid">{errors.firstName.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama Belakang</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        defaultValue={formData.lastName}
                        placeholder="Masukkan Nama Admin"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.lastName && <span className="custom-invalid">{errors.lastName.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    {/* <label className="form-label">Pilih No HP. </label>
                      <RSelect
                        name="phone"
                        placeholder="Pilih Nomor Whatsapp"
                        options={channelOption}
                        onChange={(e) => channelSelected(e)}
                        forwardref={register({
                          required: "This field is required",
                        })}
                      /> */}
                    <FormGroup>
                      <label className="form-label">Nomor Handphone</label>
                      <div className="form-control-wrap">
                        <div className="input-group">
                          <RSelect
                            name="phonePrefix"
                            options={opsiCountries}
                            defaultValue={{
                              value: "+62",
                              label: (
                                <div>
                                  <img src="https://flagcdn.com/id.svg" style={{ width: 25 }} alt="ID" /> +62
                                </div>
                              ),
                            }}
                            onChange={(e) => {
                              setValue("phonePrefix", e.value);
                              const fd = formData;
                              fd.phonePrefix = e;
                              setFormData({ ...fd });
                            }}
                            //ref={register()}
                            // {...register("phonePrefix", { required: false })}
                            styles={{
                              control: (provided, state) => ({
                                // Styles for the control (input)
                                ...provided,
                                width: 100,
                                borderRadius: 0,
                                minHeight: 36,
                              }),
                            }}
                          />
                          <input
                            className="form-control"
                            type="number"
                            name="phone"
                            placeholder="e.g. 81234567890"
                            maxLength="13"
                            minLength="9"
                            defaultValue={formData.phone}
                            onChange={(e) => {
                              const fd = formData;
                              fd.phone = e.target.value;
                              setFormData({ ...fd });
                            }}
                            ref={register({
                              required: "This field is required",
                              pattern: {
                                value: /^[0123456789]*$/,
                                message: "invalid number",
                              },
                            })}
                          />
                        </div>
                      </div>
                      {errors.phone && <span className="custom-invalid">{errors.phone.message}</span>}
                      {formData.phone.length > 13 && <span className="custom-invalid">Max length of number is 13</span>}
                      {formData.phone.length !== 0 && formData.phone.length < 9 && (
                        <span className="custom-invalid">Min length of number is 9</span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Masukkan Email Admin"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="custom-invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Kata Sandi</label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState(!passState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            passState ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>

                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          id="password"
                          type={passState ? "text" : "password"}
                          name="password"
                          defaultValue={formData.password}
                          placeholder=""
                          className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                      </div>
                      {errors.password && <span className="custom-invalid">{errors.password.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Hak Akses</label>
                      <RSelect
                        name="role"
                        options={opsiRole}
                        placeholder="Pilih Hak Akses"
                        onChange={(e) => {
                          setValue("role", e.value);
                          roleSelected(e);
                          setSelectedRole(e.value);
                        }}
                        defaultValue={""}
                        {...register("role", { required: true })}
                      />
                      {errors.role && !selectedRole.length && (
                        <span className="custom-invalid">This field is required</span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Konfirmasi Kata Sandi</label>
                      <div className="form-control-wrap">
                        <a
                          href="#confirmPassword"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setConfirmState(!confirmState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            confirmState ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>

                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          type={confirmState ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          className={`form-control-lg form-control ${confirmState ? "is-hidden" : "is-shown"}`}
                          placeholder=""
                          defaultValue=""
                          ref={register({
                            required: "This field is required",
                          })}
                        />
                      </div>
                      {errors.confirmPassword && (
                        <span className="custom-invalid">{errors.confirmPassword.message}</span>
                      )}
                      {watch("confirmPassword") !== watch("password") && getValues("confirmPassword") ? (
                        <span className="custom-invalid">password not match</span>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Department</label>
                      <RSelect
                        name="department"
                        isMulti
                        styles={{
                          multiValue: (styles, { data }) => {
                            const color = data?.color;
                            const isGradient = color.startsWith("linear");
                            return {
                              ...styles,
                              backgroundImage: (isGradient ? color : undefined) + " !important",
                              backgroundColor: (isGradient ? undefined : color) + " !important",
                              borderRadius: "1.5rem !important",
                              color: getTextColor(color),
                            };
                          },
                          multiValueLabel: (styles, { data }) => {
                            const color = getTextColor(data?.color);
                            return {
                              ...styles,
                              color: color + " !important",
                            };
                          },
                        }}
                        options={opsiDepartement}
                        placeholder="Pilih Department"
                        defaultValue={""}
                        onChange={(e) => {
                          setValue("department", e);
                          departementSelected(e);
                          setSelectedDepartment(e);
                        }}
                        // {...registerEdit("deparment", { required: true })}
                      ></RSelect>
                      {/* {errorsEdit.deparment && !selectedDepartement.length && <span className="custom-invalid">This field is required</span>} */}
                    </FormGroup>
                    {/* <FormGroup>
                      <label className="form-label">Department</label>
                      <RSelect
                        name="department"
                        options={opsiDepartement}
                        placeholder="Pilih Department"
                        onChange={(e) => {
                          setValue("department", e.value);
                          departementSelected(e);
                          setSelectedDepartment(e.value);
                        }}
                        defaultValue={""}
                        {...register("department", { required: true })}
                      />
                      {errors.department && !selectedDepartment.length && (
                        <span className="custom-invalid">This field is required</span>
                      )}
                    </FormGroup> */}
                  </Col>
                  <Col size="12">
                    <FormGroup>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          id="isActive"
                          name="isActive"
                          defaultChecked
                          defaultValue={true}
                          ref={register()}
                        />
                        <label className="custom-control-label" htmlFor="isActive">
                          Aktif
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          color="primary"
                          outline
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modal.detail}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ detail: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Detail User</h5>
              <Block className="d-flex justify-content-end">
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          <Button
                            color="primary"
                            className={editContent ? "btn d-none" : ""}
                            onClick={() => setEditContent(true)}
                          >
                            Ubah
                          </Button>
                        </li>
                        {roleFunctions.includes("delete") && (
                          <li className="nk-block-tools-opt">
                            <Button
                              color="danger"
                              outline
                              className="btn"
                              onClick={() => onDeleteClick(formData.id, formData.firstName + " " + formData.lastName)}
                            >
                              Hapus
                            </Button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </Block>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama Depan</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        defaultValue={formData.firstName}
                        disabled={!editContent}
                        placeholder="Masukkan Nama Admin"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.firstName && <span className="custom-invalid">{errors.firstName.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama Belakang</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        defaultValue={formData.lastName}
                        disabled={!editContent}
                        placeholder="Masukkan Nama Admin"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.lastName && <span className="custom-invalid">{errors.lastName.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      {/* <RSelect
                        name="phone"
                        placeholder="Pilih Nomor Whatsapp"
                        options={channelOption}
                        onChange={(e) => channelSelected(e)}
                        defaultValue={{ value: formData.phone, label: formData.phone }}
                        forwardref={register({
                          required: "This field is required",
                        })}
                      /> */}
                      <label className="form-label">Nomor Handphone</label>
                      <div className="form-control-wrap">
                        <div className="input-group">
                          <RSelect
                            disabled={!editContent}
                            name="phonePrefix"
                            options={opsiCountries}
                            defaultValue={formData.phonePrefix}
                            onChange={(e) => {
                              setValue("phonePrefix", e.value);
                              const fd = formData;
                              fd.phonePrefix = e;
                              setFormData({ ...fd });
                            }}
                            {...register("phonePrefix", { required: false })}
                            styles={{
                              control: (provided, state) => ({
                                // Styles for the control (input)
                                ...provided,
                                width: 100,
                                borderRadius: 0,
                                minHeight: 36,
                              }),
                            }}
                          />
                          <input
                            disabled={!editContent}
                            type="number"
                            name="phone"
                            className="form-control"
                            placeholder="e.g. 81234567890"
                            defaultValue={formData.phone}
                            onChange={(e) => {
                              const fd = formData;
                              fd.phone = e.target.value;
                              setFormData({ ...fd });
                            }}
                            ref={register({
                              required: "This field is required",
                              pattern: {
                                value: /^[0123456789]*$/,
                                message: "invalid number",
                              },
                            })}
                          />
                        </div>
                        {errors.phone && <span className="custom-invalid">{errors.phone.message}</span>}
                        {formData.phone.length > 13 && (
                          <span className="custom-invalid">Max length of number is 13</span>
                        )}
                        {formData.phone.length !== 0 && formData.phone.length < 9 && (
                          <span className="custom-invalid">Min length of number is 9</span>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        disabled={!editContent}
                        defaultValue={formData.email}
                        placeholder="Masukkan Email Admin"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="custom-invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Hak Akses</label>
                      <RSelect
                        name="role"
                        options={opsiRole}
                        placeholder="Pilih Hak Akses"
                        onChange={(e) => {
                          setValue("role", e.value);
                          roleSelected(e);
                          setSelectedRole(e.value);
                        }}
                        defaultValue={{ value: formData.roleId, label: formData.role }}
                        disabled={!editContent}
                        // {...register("role", { required: true })}
                        {...register("role", { required: false })}
                      />
                      {errors.department && !selectedRole.length && (
                        <span className="custom-invalid">{errors.role?.message}</span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" />
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Department</label>
                      <RSelect
                        name="department"
                        isMulti
                        disabled={!editContent}
                        styles={{
                          multiValue: (styles, { data }) => {
                            const color = data?.color;
                            const isGradient = color.startsWith("linear");
                            return {
                              ...styles,
                              backgroundImage: (isGradient ? color : undefined) + " !important",
                              backgroundColor: (isGradient ? undefined : color) + " !important",
                              borderRadius: "1.5rem !important",
                              color: getTextColor(color),
                            };
                          },
                          multiValueLabel: (styles, { data }) => {
                            const color = getTextColor(data?.color);
                            return {
                              ...styles,
                              color: color + " !important",
                            };
                          },
                        }}
                        options={filterOptions(opsiDepartement, selectedDepartment)}
                        defaultValue={formData.department}
                        placeholder="Pilih Department"
                        onChange={(e) => {
                          setValue("department", e);
                          departementSelected(e);
                          // setSelectedDepartment(e);
                        }}
                        // {...registerEdit("deparment", { required: true })}
                      ></RSelect>
                      {errors.department && !selectedDepartment.length && (
                        <span className="custom-invalid">{errors.department.message}</span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <FormGroup>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          id="isActive"
                          name="isActive"
                          defaultChecked={formData.active}
                          disabled={!editContent}
                          defaultValue={formData.active}
                          // ref={register({
                          //   required: "This field is required",
                          // })}
                          onChange={handleChangeActive}
                          ref={register()}
                        />
                        <label className="custom-control-label" htmlFor="isActive">
                          Aktif
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12" className={editContent ? "" : "d-none"}>
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          outline
                          color="primary"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit" disabled={!editContent}>
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Users;
