import React, { useState, useEffect } from "react";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Button,
  Col,
  Icon,
  Row,
} from "../../components/Component";
import { Card } from "reactstrap";
import GoogleLogo from "../../images/icons/custom/google.svg";
import LoginIllustration from "../../images/illustration/login-illustrator.svg";
import LogoDark from "../../images/mainLogoFlagship.png";
import LoginLeftCorner from "../../images/illustration/login-left-corner.svg";
import LoginRightCorner from "../../images/illustration/login-right-corner.svg";
import DotEffect from "../../images/illustration/dot_effect.svg";

import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import Head from "../../layout/head/Head";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { signInAstronacci, signInUser } from "../../services/auth";
import { DefaultRoleFunctions } from "data/DefaultRoleFunctions";
import { DefaultKeyLeadsFilter } from "data/DefaultLeadFilters";
import { serviceGetSodPermission } from "services/sod-permissions";

const Login = () => {

  const [cookies, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  useEffect(() => {
    if (cookies.SIDikey && typeof cookies.SIDikey == String) {
      console.log(cookies.SIDikey);
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      );
    } else {
      setLoading(false);
    }
  }, [cookies]);  

  const onFormSubmit = async (formData) => {
    setLoading(true);
    const responseSignIn = await signInUser(formData.email, formData.passcode); 
    const responseSignInAstro = await signInAstronacci(process.env.REACT_APP_ASTRO_DB_USER, process.env.REACT_APP_ASTRO_DB_PASS);
    
    if (responseSignIn.status === 200) {
      const data = responseSignIn.data;
      const deptIds = JSON.stringify(data.user.departments.map((itm) => itm.id))
      const pcData = JSON.stringify(data.user.departments.map((itm) => { return({deptId:itm.id, pc: itm.pc})}))

      setCookie("SIDikey", data.token.accessToken);
      setCookie("id", data.user.id);
      setCookie("firstName", data.user.firstName);
      setCookie("lastName", data.user.lastName);
      setCookie("role", data.user.role);
      setCookie("email", data.user.email);
      setCookie("avatar", data.user.avatar || "images/db2e0c20-5b64-11ef-8c72-43ed9a2dc459.png");
      setCookie("phone", data.user.phone);
      setCookie("departmentId",data.user.departments[0]?.id);
      setCookie("departmentIds", deptIds);
      setCookie("pcData", pcData);
      setCookie("roleFunctions",DefaultRoleFunctions);

      if(!localStorage.getItem("leadFilters")){
        localStorage.setItem("leadFilters", DefaultKeyLeadsFilter);
      }

      const permissionResponse = await serviceGetSodPermission(data.token.accessToken);
      if(permissionResponse.status === 200){
        console.log(permissionResponse)
        localStorage.setItem("permission", JSON.stringify(permissionResponse.data.data));
      }

      if(responseSignInAstro.status === 200){
        const token = responseSignInAstro.data.access_token;
        setCookie("AstroKey", token);
      } 

      setTimeout(() => {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
          "auth-login",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
        );
        window.location.reload();
      }, 1000);
      
    } else {
      console.log('error');
      setTimeout(() => {
        setError("Cannot login with credentials");
        setLoading(false);
      }, 2000);
    }
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Login" />
      <Block className="nk-block-middle">
        <Row className="row-cols-2 login-page login-page">
          <Col className="login-col" md="5" xs="12" lg="5" xxl="5">
            <Block className="d-flex justify-content-start align-items-center h-100">
              <Card className="card-bordered login-card shadow-lg">
                <div className="">
                  <Block className="nk-block-middle">
                    <div className="brand-logo pb-4 text-center">
                      <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                        {/* <img className="logo-light login-logo" src={Logo} alt="logo" /> */}
                        <img className="logo-dark login-logo" src={LogoDark} alt="logo-dark" />
                      </Link>
                    </div>
                    <BlockHead>
                      <BlockContent>
                        <BlockTitle tag="h6">Login into your account</BlockTitle>
                      </BlockContent>
                    </BlockHead>
                    {errorVal && (
                      <div className="mb-3">
                        <Alert color="danger" className="alert-icon">
                          {" "}
                          <Icon name="alert-circle" /> Unable to login with credentials{" "}
                        </Alert>
                      </div>
                    )}
                    <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                      <FormGroup>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Email Address
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            id="default-01"
                            name="email"
                            ref={register({ required: "This field is required" })}
                            className="form-control-lg form-control"
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#password"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setPassState(!passState);
                            }}
                            className={`form-icon lg form-icon-right passcode-switch ${
                              passState ? "is-hidden" : "is-shown"
                            }`}
                          >
                            <Icon name="eye" className="passcode-icon icon-show"></Icon>

                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                          </a>
                          <input
                            type={passState ? "text" : "password"}
                            id="password"
                            name="passcode"
                            ref={register({ required: "This field is required" })}
                            className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                          />
                          {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                            Forgot Password?
                          </Link>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Button size="lg" className="btn-login btn-block " type="submit">
                          {loading ? <Spinner size="sm" color="light" /> : "Login"}
                        </Button>
                      </FormGroup>
                    </Form>
                    <div className="text-center pt-4 pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <Button size="lg" className="btn-block btn-outlined" type="button" outline color="light">
                      <img className="mr-2" src={GoogleLogo}></img>
                      Sign in with Google
                    </Button>
                  </Block>
                </div>
              </Card>
              <img src={LoginLeftCorner} className="login-corner-left"></img>
            </Block>
          </Col>
          <Col className="login-col" md="7" xs="12" lg="7" xxl="7">
            <img src={LoginRightCorner} className="login-corner-right" />
            <img src={DotEffect} className="login-dot-effect" />
            <div className="login-illustration d-flex justify-items-center">
              <div className="my-auto">
                <img src={LoginIllustration} />
                <p className="text-center">Get The Job Done Perfectly!!</p>
              </div>
            </div>
          </Col>
        </Row>
      </Block>
    </React.Fragment>
  );
};
export default Login;
