import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { BlockHeadContent, BlockTitle, Icon, UserAvatar } from "components/Component";
import Content from "layout/content/Content";
import { Route, Switch, Link } from "react-router-dom";
import { findUpper } from "utils/Utils";
import Profile from "./Profile";
import Notification from "./Notification";
import { useCookies } from "react-cookie";

const SystemSettingsLayout = () => {
  const [cookies] = useCookies(["user"]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  return (
    <React.Fragment>
      <Content>
        <BlockHeadContent>
          <BlockTitle tag="h3" page className="mb-4">
            System Setting
          </BlockTitle>
        </BlockHeadContent>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/system-settings-profile`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/system-settings-profile`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Edit Profile</span>
                      </Link>
                    </li>
                    {cookies.role.name.includes("SALES") || (
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/system-settings-notification`}
                          className={
                            window.location.pathname === `${process.env.PUBLIC_URL}/system-settings-notification`
                              ? "active"
                              : ""
                          }
                        >
                          <Icon name="bell-fill"></Icon>
                          <span>Notification</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/system-settings-profile`}
                  render={() => <Profile updateSm={updateSm} sm={sm} />}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/system-settings-notification`}
                  render={() => <Notification updateSm={updateSm} sm={sm} />}
                ></Route>
              </Switch>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};
export default SystemSettingsLayout;
