import React, { useState, useEffect, useContext, useRef } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { filterRole, filterStatus } from "../pre-built/user-manage/UserData";
import tagData from "./TagData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Card,
  CardBody,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { TagsContext } from "./TagsContext";
import { bulkActionOptions } from "../../utils/Utils";
import SelectMenuButton from "../../components/select/SelectMenuButton";
import getTextColor from "../../components/custom/getTextColor";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import OptionWithButton from "../../components/select/OptionWithButton";
import { deleteTags, getTags, postTags, putTags } from "../../services/tags";
import { getTagCategories, getTagCategoriesList,postTagCategories } from "../../services/tagCategories";
import moment from "moment";
import ColorPicker from "react-best-gradient-color-picker";
import { DefaultRoleFunctions } from "data/DefaultRoleFunctions";
import { getDepartments } from "services/departments";
import { serviceGetProfile } from "services/settings";
import { formatDate } from "@fullcalendar/react";
import ChatModalResolve from "pages/app/chat/ChatModalResolve";

const Tags = () => {
  const nonFixedRef = useRef([]);
  const nonFixedSlug = useRef([]);
  nonFixedSlug.current = [];
  nonFixedRef.current = [];
  const [nonFixedHeight, setNonFixedHeight] = useState([]);
  const { contextData } = useContext(TagsContext);
  const [data, setData] = contextData;
  const [cookies, setCookie] = useCookies(["user"]);
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [passState, setPassState] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataDepartment, setDataDepartment] = useState();
  const [selectedDepartement, setSelectedDepartment] = useState([]);
  const [modal, setModal] = useState({
    detail: false,
    add: false,
  });
  const [editContent, setEditContent] = useState(false);
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    category: "",
    departments: [{id: "value_department1"}],
    color: "#000000"
  });
  const [metaData, setMetaData] = useState({
    itemCount: 0,
    page: 0,
    take: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [dataCategory, setDataCategory] = useState([]);
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["tags"].access || [];
  const refreshCategory = async () => {
    const responseGetTagCategories = await getTagCategoriesList(cookies.SIDikey);
    if (new RegExp(/20+/).test(responseGetTagCategories.status)) {
      const resultData = responseGetTagCategories.data;
      const finalData = [];
      resultData.forEach((category) => {
        const tempData = {
          value: category.id,
          label: category.name,
        };
        finalData.push(tempData);
      });
      setDataCategory(finalData);
    } else {
      console.log(responseGetTagCategories);
    }
  };

  const refreshDepartment = async () => {
    
    const responseGetDepartment = await getDepartments(cookies.SIDikey, '', cookies.departmentIds, cookies.id, roleFunctions);
    if (new RegExp(/20+/).test(responseGetDepartment.status)) {
      const resultData = responseGetDepartment.data.data;
      const finalData = [];
      for(let departement of resultData){
        const tempData = {
          value: departement.id,
          label: departement.name,
          color: departement.color,
        };
        // if (!roleFunctions.includes("Access_All_Dept")) {
        //   for (let departments of dataProfile){  
        //     if (departement.id !== departments.id) {
        //       console.log("department", departments)
        //       continue;
        //     }
        //   }
        // }
        finalData.push(tempData);
       }
      setDataDepartment(finalData);
    } else {
      console.log(responseGetDepartment);
    }
  };

  const getProfile = async () => {
    const responseProfile = await serviceGetProfile(cookies.SIDikey);
    const resultData = responseProfile.data.departments;
    const finalData = [];
    for (let departments of resultData){
      const tempData = {
        id: departments.id,
        color: departments.color,
        name: departments.name,
      }
      finalData.push(tempData)
    }
    setDataProfile(finalData);
  };
 
  const addCategory = async (submitData) => {
    const { name } = submitData;

    const responsePostTagCategories = await postTagCategories(cookies.SIDikey, { name: name });
    if (new RegExp(/20+/).test(responsePostTagCategories.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        refreshCategory();
        setAddCategoryModal(false);
      });
    } else if (responsePostTagCategories.status === 400) {
      if (responsePostTagCategories.data.message === "error.tagCategoryAlreadyExists") {
        Swal.fire("Gagal Add Category Tag", "Name Duplicate", "error");
      }
    } else {
      console.log(responsePostTagCategories);
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await getProfile(); // Mendapatkan dataProfile
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
  
    fetchProfile();
  }, []);

  // unselects the data on mount
  // Mengambil data berdasarkan dataProfile
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pastikan dataProfile sudah terisi sebelum memanggil refreshData
        if (dataProfile.length > 0) {
          await refreshData(1);
        }

        // Panggil fungsi lainnya
        await refreshCategory();
        await refreshDepartment();
        
      } catch (error) {
        console.error("Error refreshing data:", error);
      }
    };

    fetchData();
  }, [dataProfile]); // Bergantung pada dataProfile

  // Memproses tagData dan mengupdate state data
  useEffect(() => {
    if (tagData) {
      let newData = tagData.map((item) => {
        item.checked = false;
        return item;
      });
      setData([...newData]);
    }
  }, [tagData]); 
  
  useEffect(() => {
    refreshCategory();
    refreshData(1);
    refreshDepartment()
    getProfile();
    let newData;
    newData = tagData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, [onSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = tagData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...tagData]);
    }
  }, [onSearchText, setData]);

  const refreshHeight = () => {
    if (nonFixedRef.current) {
      const tempHeight = [];
      nonFixedRef.current.map((ref) => {
        const height = ref.offsetHeight;
        tempHeight.push(height);
        return height;
      });
      setNonFixedHeight(tempHeight);
    }
  };
  const refreshData = async (pageNumber) => {
      // const departmentsids = dataProfile.map(item => item.id);
      // console.log("testS",departmentsids.join(','))
      const responseTag = await getTags(cookies.SIDikey, roleFunctions, cookies.departmentIds, onSearchText, pageNumber);
      setMetaData(responseTag.data.meta);
      if (responseTag.status === 200) {
        const resultData = responseTag.data;

        const finaldata = [];
        resultData.data.forEach((tag) => {
          let updatedBy;
          if (tag.updatedBy != null) {
            updatedBy = tag.updatedBy.firstName + " " + tag.updatedBy.lastName;
          } else {
            updatedBy = "-";
          }
          const tempData = {
            id: tag.id,
            name: tag.name,
            color: tag.color,
            category: tag.tagCategory,
            departments: tag.departments?.map(department => ({
              id: department.id || "53237a91-3fc4-4e1a-8b05-cd3259ab57fa",
              label: department.name || "ASHOP",
              color: department.color || "#fffff"
            })),            
            createdAt: tag.createdAt, 
            updatedAt: tag.updatedAt,
            createdBy: tag.createdBy.firstName + " " + tag.createdBy.lastName,
            updatedBy: updatedBy,
          };
          finaldata.push(tempData);
        });
        setData(finaldata);
        refreshHeight();
      } else {
        console.log(responseTag);
      }
    };

  const addToRefs = (el) => {
    if (el && !nonFixedRef.current.includes(el)) {
      nonFixedRef.current.push(el);
    }
  };


  // function to reset the form
  const resetForm = () => {
    setFormData({
      id: "",
      name: "",
      category: "",
      departments: [],
      color: "#000000",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ detail: false, add: false });
    setEditContent(false);
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async (submitData) => {
    const finalCategory = formData.category;
    const { name } = submitData;
    
    const departmentValues = formData.departments.map((department) => ({ id: department.value }));

    const data = {
      name: name,
      color: formData.color,
      tagCategoryId: finalCategory.value,
      departments: departmentValues,
    };

    if (data.tagCategoryId === undefined) {
      Swal.fire("Gagal", "Mohon pilih kategori Tag", "error");
      return;
    }

    if (data.departments.length === 0) {
      Swal.fire("Gagal", "Mohon pilih department", "error");
      return;
    }

    const responsePostTags = await postTags(cookies.SIDikey, data);

    if (new RegExp(/20+/).test(responsePostTags.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        refreshData(1);
        resetForm();
        setModal({ detail: false }, { add: false });
        setEditContent(false);
      });
    } else if (responsePostTags.status === 400) {
      if (responsePostTags.data.message === "error.tagAlreadyExists") {
        Swal.fire("Gagal Add Tag", "Name Duplicate", "error");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  // submit function to update a new item
  const onEditSubmit = async () => {
    
    const finalCategory = formData.category;
    const departmentValues = formData.departments.map((department) => ({ id: department.value }));

    const data = {
      name: formData.name,
      color: formData.color,
      tagCategoryId: finalCategory.value,
      departments: departmentValues,
    };

    if (!data.name.length) {
      Swal.fire("Gagal", "Mohon nama tag diisi", "error");
      return;
    }

    if (data.departments.length === 0) {
      Swal.fire("Gagal", "Mohon pilih department", "error");
      return;
    }

    const responsePutTags = await putTags(formData.id, cookies.SIDikey, data);
    if (new RegExp(/20+/).test(responsePutTags.status)) {
      Swal.fire({
        icon: "success",
        title: "Succesfull Transasction",
        focusConfirm: false,
      }).then(() => {
        refreshData(1);
        resetForm();
        setModal({ detail: false });
        setEditContent(false);
      });
    } else if (responsePutTags.status === 400) {
      if (responsePutTags.data.message === "error.tagAlreadyExists") {
        Swal.fire("Gagal Edit Tag", "Name Duplicate", "error");
      }
    } else {
      console.log(responsePutTags);
      Swal.fire({
        icon: "error",
        title: "Unsuccesfull Transasction",
        focusConfirm: false,
      });
    }
  };

  const onDeleteClick = async (id, name) => {
    Swal.fire({
      title: "Are you sure for delete " + name + " ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const responseDeleteTags = await deleteTags(id, cookies.SIDikey);
        if (new RegExp(/20+/).test(responseDeleteTags.status)) {
          Swal.fire({
            icon: "success",
            title: "Succesfull Transasction",
            focusConfirm: false,
          }).then(() => {
            refreshData(1);
            resetForm();
            setModal({ detail: false }, { add: false });
            setEditContent(false);
          });
        } else if (responseDeleteTags.data.statusCode === 400) {
          Swal.fire({
            icon: "error",
            title: "Delete Tag Failed",
            text: "This tag is used on another page. Please contact the super admin to confirm if you want to forcibly delete this tag.",
            focusConfirm: false,
          });
        } else {
          const title =
            responseDeleteTags.data.statusCode === 409
              ? "Can not delete tag, tag used at another data"
              : "Unsuccesfull Transasction";
          Swal.fire({
            icon: "error",
            title: title,
            focusConfirm: false,
          });
        }
      }
    });
  };

  const onDetailClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setFormData({
          id: item.id,
          name: item.name,
          category: { value: item.category?.id, label: item.category?.name },
          departments: item.departments.map(department => ({
            value: department.id || "53237a91-3fc4-4e1a-8b05-cd3259ab57fa",
            label: department.label || "ASHOP",
            color: department.color || "#fffff"
          })),
          color: item.color,
        });
        setModal({ detail: true, add: false });
        setEditedId(id);
        setSelectedDepartment(item.departments)
      }
    });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    onDetailClick(id);
    setEditContent(true);
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  const categorySelected = (e) => {
    console.log(e)
    setFormData({
      ...formData,
      category: e,
    });
  };

  const departmentSelected = (e) => {
    const finalDepartment = [];
    e.forEach((element) => {
      const tempDepartment = {
        value: element.value,
        label: element.label,
        color: element.color,
      };
      finalDepartment.push(tempDepartment);
    });
    setFormData({
      ...formData,
      departments: finalDepartment
    });
    console.log(formData.departments)
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);
  const initialErrors = {
    name: null,
  };
  const [ errorsEdits, setErrors] = useState(initialErrors);
  const { errors, register, setValue, handleSubmit } = useForm();
  const { errors: errorsEdit, register: registerEdit, handleSubmit: handleEditTags } = useForm();
  const { errors: errorsCategory, register: registerCategory, handleSubmit: handleSubmitCategory } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data
    .slice(indexOfFirstItem, indexOfLastItem)
    .sort((a, b) => new moment(b.updatedAt) - new moment(a.updatedAt));
  // Change Page
  const paginate = (pageNumber) => refreshData(pageNumber);

  const currentFormdataItem = currentItems?.find((item) => item.id === formData.id) || {};
  return (
    <React.Fragment>
      <Head title="Tag"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Tag
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <Card className="card-bordered my-4 card-bluesky">
            <CardBody className="card-bluesky-body">
              <strong>Info:</strong>
              <p>Add a Tag and it's Color to create a Tag Label</p>
            </CardBody>
          </Card>
          <Block className="d-flex justify-content-end">
            <BlockHeadContent style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="form-control-wrap ">
                    <div className="form-icon form-icon-left">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="default-04"
                      placeholder="Search"
                      onInput={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="toggle-wrap nk-block-tools-toggle" style={{ display: "flex", alignItems: "center" }}>
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                {!roleFunctions.includes("create") || (
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn" onClick={() => setModal({ add: true })}>
                          <i className="ni ni-plus"></i>
                          <span>Tambah Baru</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </BlockHeadContent>
          </Block>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch" style={{ borderRadius: "20px" }}>
            <Row>
              <Col md="4" lg="3" sm="5" xs="6" className="fixed-column border-right">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow customStyle={{ width: 50 }}>
                      <span className="sub-text">No</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Nama Tag</span>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow>
                              <span>{(metaData.page - 1) * metaData.take + (index + 1)}</span>
                            </DataTableRow>
                            <DataTableRow
                              noWrap
                              customStyle={nonFixedHeight.length > 0 ? { height: nonFixedHeight[index] } : {}}
                            >
                              <span
                                className="badge rounded-pill text-bg-primary"
                                style={{
                                  background: item?.color,
                                  color: getTextColor(item?.color),
                                }}
                              >
                                {item.name}
                              </span>
                              {/* <span className="tb-lead"></span> */}
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
              <Col md="8" lg="9" sm="7" xs="6" className="nfixed-column">
                <DataTableBody compact>
                  <DataTableHead style={{ backgroundColor: "#F5F6FB" }}>
                    <DataTableRow noWrap>
                      <span className="sub-text">Kategori</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Department</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Warna</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Aksi</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Modify At</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Modify By</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Created At</span>
                    </DataTableRow>
                    <DataTableRow noWrap>
                      <span className="sub-text">Created By</span>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item, index) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow noWrap>
                              <span>{item.category?.name}</span>
                            </DataTableRow>
                            <DataTableRow>
                              {item.departments?.map((department, index) => (
                                <span key={index}>
                                  <span
                                className="badge rounded-pill text-bg-primary"
                                style={{
                                  background: department?.color,
                                  color: getTextColor(department?.color),
                                }}
                              >
                                {department.label}
                              </span>
                                  {index !== item.departments?.length - 1}
                                </span>
                              ))}
                            </DataTableRow>
                            <DataTableRow noWrap ref={addToRefs}>
                              <span className="departement-circle" style={{ background: item.color }}></span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <ul className="nk-tb-actions table-action">
                                <li className="nk-tb-action" onClick={() => onDetailClick(item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"detail" + item.id}
                                    icon="eye-fill"
                                    direction="top"
                                    text="Detail"
                                  />
                                </li>
                                {roleFunctions.includes("update") || item?.createdBy.includes(cookies.firstName) ? (
                                  <li className="nk-tb-action" onClick={() => onEditClick(item.id)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"edit" + item.id}
                                      icon="edit-alt-fill"
                                      direction="top"
                                      text="Edit"
                                    />
                                  </li>
                                ) : (
                                  ""
                                )}
                                {roleFunctions.includes("delete") || item?.createdBy.includes(cookies.firstName) ? (
                                  <li className="nk-tb-action" onClick={() => onDeleteClick(item.id, item.name)}>
                                    <TooltipComponent
                                      tag="a"
                                      containerClassName="btn btn-trigger btn-icon"
                                      id={"delete" + item.id}
                                      icon="trash-fill"
                                      direction="top"
                                      text="Hapus"
                                    />
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.updatedAt).format("MMM DD, YYYY, h:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.updatedBy}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{moment(item.createdAt).format("MMM DD, YYYY, h:mm")}</span>
                            </DataTableRow>
                            <DataTableRow noWrap>
                              <span>{item.createdBy}</span>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })
                    : null}
                </DataTableBody>
              </Col>
            </Row>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={metaData?.take}
                  totalItems={metaData?.itemCount}
                  paginate={paginate}
                  currentPage={metaData?.page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal
          isOpen={modal.add}
          backdrop="static"
          keyboard={false}
          toggle={() => setModal({ add: false })}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Tag</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Kategori Tag</label>
                      <RSelect
                        name="category"
                        btnAdd={() => setAddCategoryModal(true)}
                        btnDelete={() => refreshCategory()}
                        table="tag-categories"
                        options={dataCategory}
                        onChange={(e) => categorySelected(e)}
                        components={{ MenuList: SelectMenuButton, Option: OptionWithButton }}
                        defaultValue={formData.category}
                      ></RSelect>
                      {errors.category && <span className="invalid">{errors.category.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6" />
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name" 
                        defaultValue={formData.name}
                        placeholder="Ketik nama tag"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6" />
                  <Col md="6">
                  <FormGroup>
                    <label className="form-label">Department</label>
                    <RSelect
                      name="deparment"
                      isMulti
                      styles={{
                        multiValue: (styles, { data }) => {
                          const color = data?.color;
                          return {
                            ...styles,
                            backgroundColor: color + " !important",
                            borderRadius: "1.5rem !important",
                          };
                        },
                        multiValueLabel: (styles, { data }) => {
                          const color = getTextColor(data?.color);
                          return {
                            ...styles,
                            color: color,
                          };
                        },
                      }}
                      options={dataDepartment}
                      placeholder="Pilih Department"
                      defaultValue={""}
                      onChange={(e) => {
                        setValue("deparment", e);
                        departmentSelected(e)
                        setSelectedDepartment(e)
                      }}
                      {...register("deparment", { required: true })}
                    ></RSelect>
                    {errors.deparment && !selectedDepartement.length && <span className="custom-invalid">This field is required</span>}
                  </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label className="form-label">Warna</label>
                      <ColorPicker
                        name="color"
                        value={formData.color}
                        onChange={(e) => setFormData({ ...formData, color: e })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          outline
                          color="primary"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                        >
                          Batal
                        </Button>
                      </li>
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Simpan
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modal.detail}
          toggle={() => setModal({ detail: false })}
          backdrop="static"
          keyboard={false}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Detail Tag</h5>
              <Block className="d-flex justify-content-end">
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        {(roleFunctions.includes("update") ||
                          currentFormdataItem?.createdBy?.includes(cookies.firstName)) && (
                          <li className="nk-block-tools-opt">
                            <Button
                              color="primary"
                              className={editContent ? "btn d-none" : ""}
                              onClick={() => setEditContent(true)}
                            >
                              Ubah
                            </Button>
                          </li>
                        )}
                        {(roleFunctions.includes("delete") ||
                          currentFormdataItem?.createdBy?.includes(cookies.firstName)) && (
                          <li className="nk-block-tools-opt">
                            <Button
                              color="danger"
                              outline
                              className="btn"
                              onClick={() => onDeleteClick(formData.id, formData.name)}
                            >
                              Hapus
                            </Button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </Block>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleEditTags(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Kategori Tag</label>
                      <RSelect
                        name="category"
                        btnAdd={() => setAddCategoryModal(true)}
                        btnDelete={() => refreshCategory()}
                        defaultValue={formData.category}
                        options={dataCategory}
                        table="tag-categories"
                        onChange={(e) => categorySelected(e)}
                        components={{ MenuList: SelectMenuButton, Option: OptionWithButton }}
                        disabled={!editContent}
                      ></RSelect>
                      {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                    </FormGroup>
                  </Col>
                  <Col md="6" />
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama</label>
                      <input
                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={e => {
                          if (e.target.value === '') {
                            setErrors({ ...errorsEdits, name: 'This field is required' });
                          } else {
                            setErrors({ ...errorsEdits, name: null });
                          }
                          setFormData({ ...formData, name: e.target.value });
                        }}
                        disabled={!editContent}
                        placeholder="Ketik nama tag"
                      />
                      {errorsEdits.name && <span className="custom-invalid">{errorsEdits.name}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6" />
                  <Col md="6">
                  <FormGroup>
                    <label className="form-label">Department</label>
                    <RSelect
                      name="deparment"
                      isMulti
                      disabled={!editContent}
                      styles={{
                        multiValue: (styles, { data }) => {
                          const color = data?.color;
                          return {
                            ...styles,
                            backgroundColor: color + " !important",
                            borderRadius: "1.5rem !important",
                          };
                        },
                        multiValueLabel: (styles, { data }) => {
                          const color = getTextColor(data?.color);
                          return {
                            ...styles,
                            color: color,
                          };
                        },
                      }}
                      options={dataDepartment}
                      defaultValue={formData.departments}
                      placeholder="Pilih Department"
                      onChange={(e) => {
                        setValue("deparment", e);
                        departmentSelected(e)
                        setSelectedDepartment(e)
                      }}
                      // {...registerEdit("deparment", { required: true })}
                    ></RSelect>
                    {/* {errorsEdit.deparment && !selectedDepartement.length && <span className="custom-invalid">This field is required</span>} */}
                  </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label className="form-label">Warna</label>
                      <div
                        className={!editContent ? "" : "d-none"}
                        style={{
                          background: formData.color,
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <ColorPicker
                        className={!editContent ? "d-none" : ""}
                        name="color"
                        value={formData.color}
                        onChange={(e) => setFormData({ ...formData, color: e })}
                      />

                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    {editContent && (
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            outline
                            color="primary"
                            onClick={(ev) => {
                              ev.preventDefault();
                              onFormCancel();
                            }}
                          >
                            Batal
                          </Button>
                        </li>
                        <li>
                          <Button color="primary" size="md" type="submit">
                            Simpan
                          </Button>
                        </li>
                      </ul>
                    )}
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={addCategoryModal}
          toggle={() => setAddCategoryModal(false)}
          backdrop="static"
          keyboard={false}
          className="modal-dialog-centered"
          size="xs"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setAddCategoryModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm" />
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Kategori</h5>
              <div className="mt-4">
                <Form key="tags" className="row gy-4" onSubmit={handleSubmitCategory(addCategory)}>
                  <Col md="12" xs="12" lg="12">
                    <FormGroup>
                      <label className="form-label">Nama Kategori</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Nama Kategori"
                        ref={registerCategory({ required: "This field is required" })}
                      />
                      {errorsCategory.name && <span className="invalid">{errorsCategory.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12" xs="12" lg="12">
                    <Button type="submit" color="primary">
                      Simpan
                    </Button>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Tags;