import React, { useState, useEffect, useContext } from "react";
import FileManagerDropdown from "../../../../components/partials/file-manager-dropdown/FileManagerDropdown";
import { FileManagerContext } from "../FileManagerContext";
import { dateFormatterAlt } from "utils/Utils";
import moment from "moment";
import filemedia from "../../../../images/icons/file-media.svg";
import { useCookies } from "react-cookie";

const Grid = ({ data, setData }) => {
  const { deleteFolder, downloadFile, deleteFile } = useContext(FileManagerContext);
  const [cookies] = useCookies(["user"]);

  const [filterSelect, setFilterSelect] = useState("Last Opened");
  const [dataList, setDataList] = useState();

  useEffect(() => {
    if (data.length > 0) {
      let filteredData = data.filter((item) => Boolean(item.recovery) === false);
      setDataList([...filteredData]);
    } else {
      setDataList([]);
    }
  }, [data]);

  useEffect(() => {
    if (dataList) {
      let defaultData = dataList;
      if (filterSelect === "Name") {
        defaultData.sort(function (a, b) {
          if (a.meta.name.toUpperCase() < b.meta.name.toUpperCase()) {
            return -1;
          }
          if (a.meta.name.toUpperCase() > b.meta.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });
        setDataList([...defaultData]);
      } else if (filterSelect === "Size") {
        defaultData.sort(function (a, b) {
          if (a.meta.size < b.meta.size) {
            return -1;
          }
          if (a.meta.size > b.meta.size) {
            return 1;
          }
          return 0;
        });
        setDataList([...defaultData]);
      } else {
        let filteredData = data.filter((item) => !item.recovery);
        setDataList([...filteredData]);
      }
    }
  }, [filterSelect]);
  console.log(dataList)
  return (
    <div className="nk-files nk-files-view-grid">
      {dataList && dataList.length > 0 ? (
        <React.Fragment>
          <div className="nk-files-head">
            <div className="nk-file-item">
              <div className="nk-file-info"></div>
            </div>
          </div>
          <div className="nk-files-list">
            {dataList.map((item) => (
              <div className="nk-file-item nk-file" key={item.id}>
                <div className="nk-file-info">
                  <div className="nk-file-title">
                    <div className="nk-file-icon">
                      {/* {item.meta.type === "folder" ? (
                        <Link
                          className="nk-file-icon-link"
                          to={`${process.env.PUBLIC_URL}/app-file-manager/folder/${item.id}`}
                        >
                          <span className="nk-file-icon-type">{item.meta.svg}</span>
                        </Link>
                      ) : (
                        <a href="#link" onClick={(ev) => ev.preventDefault()} className="nk-file-icon-link">
                          <span className="nk-file-icon-type">{item.meta.svg}</span>
                        </a>
                      )} */}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          downloadFile(item);
                        }}
                        className="nk-file-icon-link"
                        rel="noopener noreferrer"
                      >
                        <span className="nk-file-icon-type">
                          <img alt="" src={filemedia} />
                        </span>
                      </div>
                    </div>
                    <div className="nk-file-name">
                      <div className="nk-file-name-text">
                        {/* {item.meta.type === "folder" ? (
                          <Link to={`${process.env.PUBLIC_URL}/app-file-manager/folder/${item.id}`} className="title">
                            {item.meta.name}
                          </Link>
                        ) : (
                          <a href="#link" onClick={(ev) => ev.preventDefault()} className="title">
                            {item.meta.name}
                          </a>
                        )} */}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(ev) => {
                            ev.preventDefault();
                            downloadFile(item);
                          }}
                          rel="noopener noreferrer"
                          className="title"
                        >
                          {item.name}
                        </div>
                        {/* <div className="asterisk">
                          <a
                            href="#folder"
                            onClick={(ev) => {
                              ev.preventDefault();
                              onStarClick(item.id);
                            }}
                            className={item.meta.starred ? "active" : ""}
                          >
                            <Icon className="asterisk-off icon ni ni-star"></Icon>
                            <Icon className="asterisk-on icon ni ni-star-fill"></Icon>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <ul className="nk-file-desc">
                    <li className="date">{moment(item.updatedAt).format("MMM DD, YYYY, h:mm")}</li>
                    {/* <li className="size">{item.meta.type === "folder" ? getTotalSize(item) : item.meta.size} MB</li>
                    {item.meta.members && <li className="members">{item.meta.members.length} Members</li>} */}

                    <li className="size">{item.size / 1000} KB</li>
                  </ul>
                </div>
                <FileManagerDropdown cookies={cookies} file={item} />
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : (
        <div>No folders or files are available</div>
      )}
    </div>
  );
};

export default Grid;
