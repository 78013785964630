import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import { Card, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { BarChartExample, Icon, LineChartExample, RSelect } from "components/Component";
// import { CampaignOverviewDataBar, CampaignOverviewDataLine } from "./homeData";
import DatePicker from "react-datepicker";
import { serviceGetListCampaigns } from "services/campaign"
import {
  getDashboardCampaignOverview,
  getDashboardCampaignOverviewTotal
} from "../../services/dashboard";

const CampaignOverview = (props) => {
  const { selectedDepartment } = props
  const [cookies] = useCookies(["user"]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  // const [dataCampaignOverview, setDataCampaignOverview] = useState([]);
  const [dataTotalChat, setDataTotalChat] = useState(null);
  const [dataTotalClosing, setDataTotalClosing] = useState(null);
  const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('YYYY-MM-DD')))
  const [endDate, setEndDate] = useState(new Date(moment().format('YYYY-MM-DD')))
  const [campaignId, setCampaignId] = useState(null);
  const [campaignLabel, setCampaignLabel] = useState(null);

  const [dataLine, setDataLine] = useState({
    labels: [],
    dataUnit: "BTC",
    lineTension: 0.4,
    categoryPercentage: 0.9,
    barPercentage: 0.6,
    datasets: [
      {
        label: "Total Send",
        backgroundColor: "white",
        pointBorderWidth: 2,
        borderColor: "#33A02C",
        fill: false,
        categoryPercentage: 0.9,
        barPercentage: 0.6,
        data: [],
      },
      {
        label: "Total Closing",
        backgroundColor: "white",
        pointBorderWidth: 2,
        borderColor: "#FF7F00",
        fill: false,
        categoryPercentage: 0.9,
        barPercentage: 0.6,
        data: [],
      },
    ],
  });
  const [dataBar, setDataBar] = useState({
    labels: [],
    dataUnit: "BTC",
    stacked: true,
    datasets: [
      {
        label: "Total Send",
        backgroundColor: "#33A02C",
        categoryPercentage: 0.5,
        barPercentage: 0.5,
        data: [],
      },
      {
        label: "Total Closing",
        backgroundColor: "#FF7F00",
        categoryPercentage: 0.5,
        barPercentage: 0.5,
        data: [],
      },
    ],
  });
  const [chartMode, setChartMode] = useState("line");
  const roleFunctions = JSON.parse(localStorage.getItem("permission"))["dashboard"].access || [];

  const getData = async (campaignId, startDate, endDate) => {
    const responseGetDataCampaign = await serviceGetListCampaigns(cookies.SIDikey, selectedDepartment.value, 'DESC', 'campaign.clickCount', roleFunctions);
    if(campaignId === null && campaignLabel === null){
      setCampaignId(responseGetDataCampaign?.data[0]?.id)
      setCampaignLabel(responseGetDataCampaign?.data[0]?.name)
    }
    const arrDataCampaign = []
    responseGetDataCampaign.data.forEach(item => {
      arrDataCampaign.push({
        label: item.name,
        value: item.id
      })
    })
    setCampaignOptions(arrDataCampaign)
    // if(campaignId){
      const responseGetDataCampaignOverview = await getDashboardCampaignOverview(cookies.SIDikey, selectedDepartment.value, startDate, endDate, campaignId || responseGetDataCampaign?.data[0]?.id, roleFunctions);
      const responseGetDataTotalChat = await getDashboardCampaignOverviewTotal(cookies.SIDikey, selectedDepartment.value, startDate, endDate, "TOTAL", campaignId || responseGetDataCampaign?.data[0]?.id, roleFunctions);
      const responseGetDataTotalClosing = await getDashboardCampaignOverviewTotal(cookies.SIDikey, selectedDepartment.value, startDate, endDate, "CLOSING", campaignId || responseGetDataCampaign?.data[0]?.id, roleFunctions);
      
      // setDataCampaignOverview(responseGetDataCampaignOverview.data)
      dataLine.labels = responseGetDataCampaignOverview.data.map(item => moment(item.day).format('ll'))
      dataLine.datasets[0].data = responseGetDataCampaignOverview.data.map(item => item.total)
      dataLine.datasets[1].data = responseGetDataCampaignOverview.data.map(item => item.closing)
      dataBar.labels = responseGetDataCampaignOverview.data.map(item => moment(item.day).format('ll'))
      dataBar.datasets[0].data = responseGetDataCampaignOverview.data.map(item => item.total)
      dataBar.datasets[1].data = responseGetDataCampaignOverview.data.map(item => item.closing)
      setDataLine(dataLine)
      setDataBar(dataBar)
      setDataTotalChat(responseGetDataTotalChat.data.pop())
      setDataTotalClosing(responseGetDataTotalClosing.data.pop())
    // }
  };

  useEffect(() => {
    getData(campaignId, startDate, endDate)
  }, [selectedDepartment]);

  const handleCampaignId = (e) => {
    setCampaignId(e.value)
    setCampaignLabel(e.label)
    getData(e.value, startDate, endDate)
  }

  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if(start && end){
      getData(campaignId, start, end)
    }
  }

  return (
    <Card className="card-full overflow-hidden">
      <div className="nk-ecwg nk-ecwg8 h-100 d-flex flex-column">
        <div className="card-inner h-100 d-flex flex-column" style={{ flex: 1 }}>
          <div className="card-title-group mb-3">
            <div className="card-title">
              <h6 className="title">Campaign Overview</h6>
            </div>
            <div className="position-relative w-20 d-flex justify-content-center align-items-center">
              <DatePicker
                className="form-control date-picker"
                selectsRange
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDate}
                dateFormat={'yyyy-MM-dd'}
              />
              <div
                className="btn btn-round btn-icon btn-light dropdown-toggle ml-2"
                style={{ backgroundColor: "unset", border: "unset" }}
              >
                {/* <Icon name="download-cloud"></Icon> */}
              </div>
            </div>
          </div>
          <div className="w-25 mb-2">
            <FormGroup>
              <RSelect 
                name="campaign"
                options={campaignOptions} 
                placeholder="Select promo..." 
                onChange={handleCampaignId} 
                value={{ label: campaignLabel, value: campaignId }}
              />
            </FormGroup>
          </div>
          <div className="mb-2 d-flex position-relative">
            <div
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                display: "flex",
                fontSize: 16,
                color: "white",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => setChartMode("line")}
                className="p-1 d-flex align-items-center"
                style={{
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  backgroundColor: chartMode === "line" ? "#4461F2" : "#C0C6D4",
                }}
              >
                <Icon name="line-chart" />
              </div>
              <div
                style={{
                  borderBottomRightRadius: 5,
                  borderTopRightRadius: 5,
                  backgroundColor: chartMode === "bar" ? "#4461F2" : "#C0C6D4",
                }}
                onClick={() => setChartMode("bar")}
                className="p-1 d-flex align-items-center"
              >
                <Icon name="bar-chart" />
              </div>
            </div>
            <Card className="card-full " style={{ height: "auto", width: "200px" }}>
              <div className="p-2">
                <p>Total Chat</p>
                <h6>{dataTotalChat?.currentTotal ? dataTotalChat?.currentTotal : 0}</h6>
                <div>
                  {" "}
                  {
                    dataTotalChat ?
                      Number(dataTotalChat?.currentTotal) !== Number(dataTotalChat?.lastTotal) ?
                        Number(dataTotalChat?.lastTotal) !== 0 ?
                          Math.round((Number(dataTotalChat?.currentTotal)-Number(dataTotalChat?.lastTotal))/Number(dataTotalChat?.lastTotal)*100) > 0 ?
                            <span className="report-panel__trend__up">+{Math.round((Number(dataTotalChat?.currentTotal)-Number(dataTotalChat?.lastTotal))/Number(dataTotalChat?.lastTotal)*100)}%</span>
                          :
                            <span className="report-panel__trend__down">{Math.round((Number(dataTotalChat?.currentTotal)-Number(dataTotalChat?.lastTotal))/Number(dataTotalChat?.lastTotal)*100)}%</span>
                        :
                          <span className="report-panel__trend__up">+100%</span>
                      :
                        <span className="report-panel__trend__up">+0%</span>
                    :
                      <span className="report-panel__trend__up">+0%</span>
                  }
                  <span> than last period</span>
                </div>
              </div>
            </Card>
            <Card className="card-full ml-3" style={{ height: "auto", width: "200px" }}>
              <div className="p-2">
                <p>Total Closing</p>
                <h6>{dataTotalClosing?.currentTotal ? dataTotalClosing?.currentTotal : 0}</h6>
                <div>
                  {" "}
                  {
                    dataTotalClosing ?
                      Number(dataTotalClosing?.currentTotal) !== Number(dataTotalClosing?.lastTotal) ?
                        Number(dataTotalClosing?.lastTotal) !== 0 ?
                          Math.round((Number(dataTotalClosing?.currentTotal)-Number(dataTotalClosing?.lastTotal))/Number(dataTotalClosing?.lastTotal)*100) > 0 ?
                            <span className="report-panel__trend__up">+{Math.round((Number(dataTotalClosing?.currentTotal)-Number(dataTotalClosing?.lastTotal))/Number(dataTotalClosing?.lastTotal)*100)}%</span>
                          :
                            <span className="report-panel__trend__down">{Math.round((Number(dataTotalClosing?.currentTotal)-Number(dataTotalClosing?.lastTotal))/Number(dataTotalClosing?.lastTotal)*100)}%</span>
                        :
                          <span className="report-panel__trend__up">+100%</span>
                      :
                        <span className="report-panel__trend__up">+0%</span>
                    :
                      <span className="report-panel__trend__up">+0%</span>
                  }
                  <span> than last period</span>
                </div>
              </div>
            </Card>
          </div>
          <div className="nk-ecwg8-ck" style={{ margin: "auto 0px" }}>
            {chartMode === "line" ? (
              <LineChartExample data={dataLine} legend />
            ) : (
              <BarChartExample data={dataBar} stacked legend />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default CampaignOverview;
