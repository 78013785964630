/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledDropdown } from "reactstrap";
import Simplebar from "simplebar-react";
import { Icon, UserAvatar } from "../../../components/Component";
import getTextColor from "../../../components/custom/getTextColor";
import { findUpper } from "../../../utils/Utils";
import {
  ButtonReplyChat,
  ContactChat,
  FileChat,
  ImageChat,
  InteractiveChat,
  ListChat,
  LocationChat,
  StickerChat,
  TextChat,
  VideoChat,
  VoiceChat,
} from "./ChatBubble";
import { ChatContext } from "./ChatContext";
import { utilNumberPhoneConvention } from "./utils/ChatUtils";

const bubbleWidth = 300;

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const MeChat = ({ cookies, item, chat, setReplyMessage, roleFunctions }) => {
  // const { value, value2 } = useContext(ReplyContext);
  // const [replyMessage, setReplyMessage] = value;

  const options = (item.type === "BUTTON_REPLY" || item.type === "LIST") && JSON.parse(item.content)?.action?.buttons;
  const mediaRet = (item) => {
    if (item.type === "IMAGE") {
      let url = "";
      if (item.content) {
        url = item.content.includes("https") ? item.content : process.env.REACT_APP_S3_URL + item.content;
      }
      return (
        <div
          className={item.type.toLowerCase() !== "text" ? "chat-msg image-bubble" : "chat-msg"}
          style={{ width: bubbleWidth }}
        >
          <ImageChat
            cookies={cookies}
            filename={item.contentText}
            caption={item.text}
            src={url}
            contextId={item.contextId}
            context={item.context}
            status={item.status}
            timestamp={timeProcessing(item.timestamp)}
            itemId={item.id}
          />
        </div>
      );
    } else if (item.type === "VIDEO") {
      return (
        <div
          className={item.type.toLowerCase() !== "text" ? "chat-msg image-bubble" : "chat-msg"}
          style={{ width: bubbleWidth }}
        >
          <VideoChat
            filename={item.contentText}
            caption={item.text}
            src={process.env.REACT_APP_S3_URL + item.content}
            contextId={item.contextId}
            context={item.context}
            status={item.status}
            timestamp={timeProcessing(item.timestamp)}
            itemId={item.id}
            cookies={cookies}
          />
        </div>
      );
    } else if (item.type === "DOCUMENT") {
      return (
        <div
          className={item.type.toLowerCase() !== "text" ? "chat-msg image-bubble" : "chat-msg"}
          style={{ width: bubbleWidth }}
        >
          <FileChat
            cookies={cookies}
            id={item?.id}
            text={item.text}
            caption={item.contentText ?? item.content.replace("media/", "")}
            contentSize={formatBytes(item.contentSize)}
            link={process.env.REACT_APP_S3_URL + item.content}
            status={item.status}
            contextId={item.contextId}
            context={item.context}
            timestamp={timeProcessing(item.timestamp)}
          />
        </div>
      );
    }
  };
  return (
    <div className="chat is-me" id={item.id}>
      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble" key={item.id}>
            {item.type === "TEXT" ? (
              <TextChat
                text={item.text}
                contextId={item.contextId}
                status={item.status}
                timestamp={timeProcessing(item.timestamp)}
                context={item.context}
                header={
                  item?.header?.type === "text" ? (
                    item?.header.text
                  ) : item?.header?.type === "image" ? (
                    <img src={item?.header.link} alt="header" />
                  ) : (
                    ""
                  )
                }
              />
            ) : (
              mediaRet(item)
            )}
            <React.Fragment>
              <ul className="chat-msg-more">
                <li className="d-none d-sm-block">
                  <a href="#delete">
                    <UncontrolledDropdown>
                      {roleFunctions.includes("chat") && (
                        <DropdownToggle tag="div" className="btn btn-icon btn-sm btn-trigger">
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                      )}
                      <DropdownMenu right>
                        <ul>
                          <li onClick={() => console.log("Reply Chat")}>
                            <DropdownItem
                              tag="div"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setReplyMessage(item);
                              }}
                            >
                              <span>Reply Chat</span>
                            </DropdownItem>
                          </li>

                          {/* <li onClick={() => console.log("Forward Chat")}>
                            <DropdownItem
                              tag="div"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Forward Chat</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => console.log("Hapus Chat")}>
                            <DropdownItem
                              tag="div"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Hapus Chat</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => console.log("Salin Chat")}>
                            <DropdownItem
                              tag="div"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span>Salin Chat</span>
                            </DropdownItem>
                          </li> */}
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </a>
                </li>
              </ul>
            </React.Fragment>
          </div>
        </div>
      </div>
      {item.type === "BUTTON_REPLY" && (
        <div style={{ width: bubbleWidth }}>
          <ButtonReplyChat
            options={options}
            text={item.text}
            status={""}
            timestamp={timeProcessing(item.timestamp)}
            header={
              item?.header?.type === "text" ? (
                item?.header.text
              ) : item?.header?.type === "image" ? (
                <img src={item?.header.link} alt="header" />
              ) : (
                ""
              )
            }
          />
        </div>
      )}
      {item.type === "LIST" && (
        <div style={{ width: bubbleWidth }}>
          <ListChat
            options={options}
            text={item.text}
            status={""}
            timestamp={timeProcessing(item.timestamp)}
            header={
              item?.header?.type === "text" ? (
                item?.header.text
              ) : item?.header?.type === "image" ? (
                <img src={item?.header.link} alt="header" />
              ) : (
                ""
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export const SessionResolved = (item) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center py-3 overflow-hidden">
        <span
          className=" text-bg-primary py-1"
          style={{
            background: "#485fee",
            borderColor: "#485fee",
            color: "white",
            fontSize: "9pt",
            maxWidth: 600,
            overflowWrap: "break-word",
            whiteSpace: "wrap",
            padding: "5px 12px",
            fontStyle: "italic",
            fontWeight: 600,
            borderRadius: "1rem",
          }}
        >
          {item.item.text.split("\n").map((str) => (
            <p key={str}>{str}</p>
          ))}
        </span>
      </div>
    </React.Fragment>
  );
};

export const dateProcessing = (timestamp) => {
  const today = moment().format("DD/MM/YYYY");
  const compared = moment.unix(timestamp / 1000).format("DD/MM/YYYY");

  if (today === compared) {
    return new Date(Number(timestamp)).toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  } else if (moment().subtract(1, "day").format("DD/MM/YYYY") === compared) {
    return "Yesterday";
  }
  return new Date(Number(timestamp)).toLocaleString("en-GB", {
    month: "numeric",
    year: "numeric",
    day: "numeric",
  });
};

export const timeProcessing = (timestamp) => {
  return new Date(Number(timestamp)).toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const YouChat = ({ item, cookies, chat, setContextMessage, setReplyMessage, roleFunctions }) => {
  const mediaRet = (item) => {
    if (item.type !== "TEXT") {
      if (item.type === "IMAGE") {
        return (
          <div
            className={item.type.toLowerCase() !== "text" ? "chat-msg image-bubble" : "chat-msg"}
            style={{ width: bubbleWidth }}
          >
            <ImageChat
              cookies={cookies}
              itemId={item.id}
              filename={item.contentText}
              caption={item.text}
              src={process.env.REACT_APP_S3_URL + item.content}
              contextId={item.contextId}
              youChat
              context={item.context}
              status={""}
              timestamp={dateProcessing(item.timestamp)}
            />
          </div>
        );
      } else if (item.type === "DOCUMENT") {
        console.log(item.contentText, item.content, item.text, item);
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <FileChat
              cookies={cookies}
              id={item.id}
              contentSize={formatBytes(item.contentSize)}
              text={item.text}
              caption={item.contentText ?? item.content.replace("media/", "")}
              link={process.env.REACT_APP_S3_URL + item.content}
              status={item.status}
              contextId={item.contextId}
              context={item.context}
              timestamp={dateProcessing(item.timestamp)}
              youChat
            />
          </div>
        );
      } else if (item.type === "VIDEO") {
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <VideoChat
              cookies={cookies}
              itemId={item.id}
              filename={item.contentText}
              caption={item.text}
              src={process.env.REACT_APP_S3_URL + item.content}
              contextId={item.contextId}
              youChat
              context={item.context}
              status={""}
              timestamp={dateProcessing(item.timestamp)}
            />
            {console.log("itemID", item.id)}
          </div>
        );
      } else if (item.type === "LOCATION") {
        const content = JSON.parse(item.content);
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <LocationChat
              name={content?.name}
              address={content?.address}
              link={content?.url}
              youChat
              contextId={item.contextId}
              context={item.context}
            />
          </div>
        );
      } else if (item.type === "VOICE") {
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <VoiceChat
              link={process.env.REACT_APP_S3_URL + item.content}
              youChat
              contextId={item.contextId}
              context={item.context}
            />
          </div>
        );
      } else if (item.type === "AUDIO") {
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <VoiceChat
              link={process.env.REACT_APP_S3_URL + item.content}
              youChat
              contextId={item.contextId}
              context={item.context}
            />
          </div>
        );
      } else if (item.type === "CONTACTS") {
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <ContactChat
              contextId={item.contextId}
              context={item.context}
              youChat
              contactData={JSON.parse(item.content)}
              timestamp={dateProcessing(item.timestamp)}
            />
          </div>
        );
      } else if (item.type === "STICKER") {
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <StickerChat
              src={process.env.REACT_APP_S3_URL + item.content}
              contextId={item.contextId}
              youChat
              context={item.context}
              status={""}
              timestamp={dateProcessing(item.timestamp)}
            />
          </div>
        );
      } else if (item.type === "INTERACTIVE") {
        return (
          <div className={item.type !== "TEXT" ? "chat-msg image-bubble" : "chat-msg"} style={{ width: bubbleWidth }}>
            <InteractiveChat
              text={item.content}
              contextId={item.contextId}
              status={""}
              timestamp={timeProcessing(item.timestamp)}
              context={"interactive"}
              youChat
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="chat is-you" id={item.id}>
      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble" key={item.id}>
            {item.type === "TEXT" ? (
              <TextChat
                text={item.text}
                contextId={item.contextId}
                status={""}
                timestamp={timeProcessing(item.timestamp)}
                context={item.context}
                youChat
                header={
                  item?.header?.type === "text" ? (
                    item?.header.text
                  ) : item?.header?.type === "image" ? (
                    <img src={item?.header.link} alt="header" />
                  ) : (
                    ""
                  )
                }
              />
            ) : (
              mediaRet(item)
            )}

            <React.Fragment>
              <ul className="chat-msg-more">
                <li className="d-none d-sm-block">
                  <a href="#delete">
                    <UncontrolledDropdown>
                      {roleFunctions?.includes("chat") && (
                        <DropdownToggle tag="div" className="btn btn-icon btn-sm btn-trigger">
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                      )}
                      <DropdownMenu right>
                        <ul>
                          <li>
                            <DropdownItem
                              tag="div"
                              href="#dropdown"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setReplyMessage(item);
                              }}
                            >
                              <span>Reply Chat</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </a>
                </li>
              </ul>
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MetaChat = ({ item }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{item}</span>
      </div>
    </div>
  );
};

export const ChatItemHeader = ({ item }) => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/app-chat`} className="chat-link">
      <UserAvatar theme={item.theme} icon="user-alt-fill" image={item.image} className="chat-media overflow-hidden">
        {/* <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span> */}
      </UserAvatar>

      <div className="chat-info ">
        <div className="chat-from">
          <div className="name">{item.nickname ? item.nickname : item.name}</div>
          <span className="time">{item.date}</span>
        </div>
        <div className="chat-context">
          <div className="text">{item.text}</div>
          {/* message di header */}
          <div className="status delivered">
            <Icon
              name={`${
                item.delivered === true ? "check-circle-fill" : item.delivered === "sent" ? "check-circle" : ""
              }`}
            ></Icon>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const ChatItem = ({
  item,
  chatItemClick,
  setSelectedId,
  setIsModalTagAssignOpen,
  setIds,
  selectedId,
  ids,
  clicked,
}) => {
  const [cookies] = useCookies(["user"]);
  const { isSavedArray } = useContext(ChatContext);
  const [isSaved] = isSavedArray;
  const [hover, setHover] = useState(false);
  const newUser = item.user;

  const deptColor = item.channel?.department?.color ?? "#fffff";
  const deptName = item.channel?.department?.name ?? "default";

  const userName =
    (newUser?.firstName || item.updatedBy?.firstName) + " " + (newUser?.lastName || item.updatedBy?.lastName);
  const userIsSaved = isSaved.find((savedUser) => savedUser.id === item.contactId)?.isSaved;
if(!item.channel?.department?.name) {
  console.log('item:',item)
}
  
  return (
    <li
      className={`chat-item ${item.unread ? "is-unread" : ""} w-100`}
      style={{ backgroundColor: clicked ? "#fafafa" : "" }}
    >
      <a
        className="chat-link"
        href="#chat-link"
        onClick={(ev) => {
          ev.preventDefault();
          chatItemClick(item.id);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {
          <UserAvatar theme={item.theme} icon="user-alt-fill" image={item.image} className="chat-media overflow-hidden">
            {/* <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span> */}
          </UserAvatar>
        }
        <div className="chat-info">
          <div className="chat-from">
            <div className="name text-truncate pr-2">
              {utilNumberPhoneConvention(item?.name ?? item?.customerPhone)}
              {/* {userIsSaved ? item?.name : utilNumberPhoneConvention(item.customerPhone)} */}
            </div>
            <div className="d-flex flex-row">
              <span className="time">{dateProcessing(item?.lastMessage?.timestamp)}</span>
              <div>
                <div
                  style={{ marginInlineStart: "5px" }}
                  className="more-icon"
                  onClick={() => {
                    setIsModalTagAssignOpen(true);
                    setIds([item.id]);
                  }}
                >
                  <Icon name="downward-ios" className={classNames({ "d-none": !hover })} />
                </div>
              </div>
            </div>
          </div>
          <div className="chat-context custom-chat-context">
            {item.lastMessage?.fromMe ? (
              <div className="status delivered mr-1 mb-1">
                <i
                  className={`bi ${
                    item.lastMessage.status === "READ"
                      ? "bi-check2-all text-info"
                      : item.lastMessage.status === "DELIVERED"
                      ? "bi-check2-all"
                      : item.lastMessage.status === "SENT"
                      ? "bi-check2"
                      : item.lastMessage.status === "FAILED"
                      ? "bi-x-circle text-danger"
                      : ""
                  }`}
                ></i>
              </div>
            ) : (
              ""
            )}
            <div className="text">
              <div className="mb-1 d-inline-flex w-100">
                <span className={item.lastMessage?.type !== "TEXT" ? "" : "d-none"} style={{ marginRight: "5px" }}>
                  <Icon
                    name={
                      item.lastMessage?.type === "IMAGE"
                        ? "img-fill"
                        : item.lastMessage?.type === "DOCUMENT"
                        ? "file-fill"
                        : item.lastMessage?.type === "VOICE"
                        ? "mic"
                        : item.lastMessage?.type === "LOCATION"
                        ? "map-pin-fill"
                        : item.lastMessage?.type === "CONTACTS"
                        ? "user-fill-c"
                        : item.lastMessage?.type === "VIDEO"
                        ? "video-fill"
                        : item.lastMessage?.type === "STICKER"
                        ? "notify"
                        : ""
                    }
                    className="text-soft"
                    style={{ fontSize: "13px" }}
                  />
                </span>
                {cookies.role.name === "SALES" || cookies.role.name === "CS" ? (
                  (item.unreadCount > 0) & !item.fromMe && !clicked ? (
                    <p style={{ color: "black" }} className="text-truncate pr-5 font-weight-bold">
                      {
                      item.lastMessage?.type === "INTERACTIVE"
                        ? (() => {
                            const parsedContent = JSON.parse(item.lastMessage?.content || '{}');
                            return parsedContent.button_reply?.title
                              ? parsedContent.button_reply.title
                              : parsedContent.list_reply?.title
                              ? parsedContent.list_reply.title
                              : item.lastMessage?.text || item.lastMessage?.type;
                          })()
                        : item.lastMessage?.text
                        ? item.lastMessage.text
                        : item.lastMessage?.type
                      }
                    </p>
                  ) : (
                    <p className="text-truncate pr-2 font-weight-light">
                        {(() => {
                          if (item.lastMessage?.type === "INTERACTIVE") {
                            try {
                              // Parsing konten JSON
                              const content = JSON.parse(item.lastMessage?.content || '{}');
                              
                              // Cek apakah button_reply ada dan memiliki title
                              if (content.button_reply?.title) {
                                return content.button_reply.title;
                              }
                              
                              // Cek apakah list_reply ada dan memiliki title
                              if (content.list_reply?.title) {
                                return content.list_reply.title;
                              }
                              
                              // Jika tidak ada button_reply dan list_reply, kembalikan text atau type
                              return item.lastMessage?.text || item.lastMessage?.type;
                            } catch (error) {
                              // Tangani error jika parsing JSON gagal
                              console.error('Error parsing JSON content:', error);
                              return item.lastMessage?.text || item.lastMessage?.type;
                            }
                          }

                          // Untuk kasus selain INTERACTIVE
                          if (item.lastMessage?.text === "") {
                            return item.lastMessage?.content?.replace("media/", "");
                          } else {
                            return item.lastMessage?.text || item.lastMessage?.type;
                          }
                        })()}
                    </p>
                  )
                ) : (item.unreadCountAdmin > 0) & !item.fromMe && !clicked ? (
                  <p style={{ color: "black" }} className="text-truncate pr-5 font-weight-bold">
                    {
                      item.lastMessage?.type === "INTERACTIVE"
                        ? (() => {
                            const parsedContent = JSON.parse(item.lastMessage?.content || '{}');
                            return parsedContent.button_reply?.title
                              ? parsedContent.button_reply.title
                              : parsedContent.list_reply?.title
                              ? parsedContent.list_reply.title
                              : item.lastMessage?.text || item.lastMessage?.type;
                          })()
                        : item.lastMessage?.text
                        ? item.lastMessage.text
                        : item.lastMessage?.type
                    }
                  </p>
                ) : (
                  <p className="text-truncate pr-2 font-weight-light">
                    {(() => {
                      if (item.lastMessage?.type === "INTERACTIVE") {
                        try {
                          // Parsing konten JSON
                          const content = JSON.parse(item.lastMessage?.content || '{}');
                          
                          // Cek apakah button_reply ada dan memiliki title
                          if (content.button_reply?.title) {
                            return content.button_reply.title;
                          }
                          
                          // Cek apakah list_reply ada dan memiliki title
                          if (content.list_reply?.title) {
                            return content.list_reply.title;
                          }
                          
                          // Jika tidak ada button_reply dan list_reply, kembalikan text atau type
                          return item.lastMessage?.text || item.lastMessage?.type;
                        } catch (error) {
                          // Tangani error jika parsing JSON gagal
                          console.error('Error parsing JSON content:', error);
                          return item.lastMessage?.text || item.lastMessage?.type;
                        }
                      }

                      // Untuk kasus selain INTERACTIVE
                      if (item.lastMessage?.text === "") {
                        return item.lastMessage?.content?.replace("media/", "");
                      } else {
                        return item.lastMessage?.text || item.lastMessage?.type;
                      }
                    })()}
                </p>
                )}
              </div>
            </div>
            <div className="position-relative">
              {cookies.role.name === "SALES" || cookies.role.name === "CS" ? (
                <Badge
                  color={`outline-${
                    item.status === "RESOLVED" && item.unreadCount === 0
                      ? "success"
                      : // : item.status === "OPENED" && item.unreadCount === 0
                      item.status === "PENDING"
                      ? "warning"
                      : item.unreadCount > 0 && !clicked
                      ? "danger"
                      : "primary"
                  }`}
                  pill
                >
                  {item.unreadCount > 0 && !clicked && item.status !== "PENDING" && item.status !== "RESOLVED"
                    ? "UNOPENED"
                    : item.status}
                </Badge>
              ) : (
                <Badge
                  color={`outline-${
                    item.adminStatus === "RESOLVED" && item.unreadCountAdmin === 0
                      ? "success"
                      : // : item.status === "OPENED" && item.unreadCount === 0
                      item.adminStatus === "PENDING"
                      ? "warning"
                      : (item.unreadCountAdmin > 0 && !clicked) || item.adminStatus === "UNOPENED"
                      ? "danger"
                      : "primary"
                  }`}
                  pill
                >
                  {/* {item.adminStatus} */}
                  {item.adminStatus === "PENDING" || item.adminStatus === "RESOLVE"
                    ? item.adminStatus
                    : item.unreadCountAdmin > 0 && !clicked
                    ? "UNOPENED"
                    : item.adminStatus}
                </Badge>
              )}

              {cookies.role.name === "SALES" || cookies.role.name === "CS"
                ? item.unreadCount > 0 &&
                  !clicked && (
                    <div className="unread-count position-absolute d-flex">
                      <span>{item.unreadCount}</span>
                    </div>
                  )
                : item.unreadCountAdmin > 0 &&
                  !clicked && (
                    <div className="unread-count position-absolute d-flex">
                      <span>{item.unreadCountAdmin}</span>
                    </div>
                  )}
            </div>
          </div>
          <Simplebar className="nk-chat-aside-body py-1">
            <div className="badge-list d-flex">
              {
                <span
                  className="badge rounded-pill text-bg-primary mr-1"
                  style={{
                    background: deptColor,
                    borderColor: "",
                    color: getTextColor(deptName),
                  }}
                >
                  {deptName}
                </span>
              }

              {/* {
                item.channel?.department.length > 0 ?
                item.channel?.department.map((itm) => {
                  return (
                    <span
                    className="badge rounded-pill text-bg-primary mr-1"
                    style={{
                      background: itm.color,
                      borderColor: "",
                      color: getTextColor(itm.color),
                    }}
                    >
                {itm.name}
              </span>
                  )
                })
              : ""
              } */}
              <span
                className="badge rounded-pill text-bg-primary"
                style={{
                  background: "#f7f5f5",
                  borderColor: "#050505",
                  color: getTextColor("#f7f5f5"),
                }}
              >
                {userName}
              </span>
              {item.tags.map((item, idx) => {
                return (
                  <span
                    key={idx}
                    className="badge rounded-pill text-bg-primary"
                    style={{
                      background: item.color,
                      borderColor: "#050505",
                      color: getTextColor(item.color),
                      margin: "2px",
                    }}
                  >
                    {item.name}
                  </span>
                );
              })}

              {item.tickets && item.tickets[0]?.campaign && (
                <span
                  className={`badge rounded-pill text-bg-primary ${item.tags.length == 0 ? "ml-1" : ""}`}
                  style={{
                    background: "#f7f5f5",
                    color: "#050505",
                  }}
                >
                  {item.tickets[0]?.campaign.name}
                </span>
              )}
            </div>
          </Simplebar>
        </div>
      </a>

      {/* <div className="chat-actions">
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu right>
            <ul className="link-list-opt no-bdr">
              <li onClick={() => checkBeforeDelete(item.id)}>
                <DropdownItem
                  tag="a"
                  href="#delete"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Delete
                </DropdownItem>
              </li>
              <li onClick={() => propAction(item.id, "unread")}>
                <DropdownItem
                  tag="a"
                  href="#unread"
                  className={item.unread ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Mark as Unread
                </DropdownItem>
              </li>
              <li onClick={() => propAction(item.id, "archive")}>
                <DropdownItem
                  tag="a"
                  href="#archive"
                  className={item.archive ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Archive Message
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div> */}
    </li>
  );
};

export const ContactItem = ({ item, setTab, setSelectedId }) => {
  return (
    <ul className="contacts-list">
      <li>
        <h6 className="title overline-title-alt">{item.contacts.length > 0 && item.title}</h6>
      </li>
      {item.contacts.map((contact, idx) => {
        return (
          <li
            key={idx}
            onClick={() => {
              setTab("Chats");
              setSelectedId(contact.id);
            }}
          >
            <div className="user-card">
              <a href="#card" onClick={(ev) => ev.preventDefault()}>
                <UserAvatar text={findUpper(contact.name)} theme={contact.theme} image={contact.image}></UserAvatar>
                <div className="user-name">{contact.name}</div>
              </a>
              <div className="user-actions">
                <Link to={`${process.env.PUBLIC_URL}/app-chat`}>Start Chat</Link>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export const SharedImage = ({ link }) => {
  const [showImage, setShowImage] = useState(false);
  const [blobImage, setBlobImage] = useState({});

  const generateFile = async (link) => {
    const newLink = link.replace(process.env.REACT_APP_S3_URL_MEDIA, process.env.REACT_APP_S3_URL_DOWNLOAD);

    fetch(newLink)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        setBlobImage(blobUrl);
      });
  };

  useEffect(() => {
    if (showImage) {
      generateFile();
    }
  }, [showImage]);

  return (
    <React.Fragment>
      <li>
        <img className="sidebar-image" src={link} alt="" onClick={() => setShowImage(true)} />
      </li>
      <Modal isOpen={showImage} toggle={() => setShowImage(false)} size="large">
        <div className="button-bar">
          <div
            className="d-flex flex-row justify-content-end p-1"
            style={{ position: "relative", width: "100vw", background: "rgba(0, 0, 0, 0.44)" }}
          >
            <a className="mr-3" href={blobImage} target="_blank" download rel="noreferrer">
              <Icon className="icon-image-download" name="download" />
            </a>
            <a onClick={() => setShowImage(false)} className="mr-2">
              <Icon name="cross" className="icon-image-download" />
            </a>
          </div>
        </div>
        <img className="rounded" src={link} style={{ height: "100%" }} alt={link} />
      </Modal>
    </React.Fragment>
  );
};

export const SharedVideo = ({ link }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [blobVideo, setBlobVideo] = useState({});

  const generateFile = async (link) => {
    const newLink = link.replace(process.env.REACT_APP_S3_URL_MEDIA, process.env.REACT_APP_S3_URL_DOWNLOAD);

    fetch(newLink)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        setBlobVideo(blobUrl);
      });
  };

  useEffect(() => {
    if (showVideo) {
      generateFile();
    }
  }, [showVideo]);

  return (
    <React.Fragment>
      <li>
        {/* <img className="sidebar-image" src={link} alt="" onClick={() => setShowVideo(true)} /> */}
        <video
          className="sidebar-image"
          // controls
          onClick={() => setShowVideo(true)}
        >
          <source src={link} />
        </video>
      </li>
      <Modal isOpen={showVideo} toggle={() => setShowVideo(false)} size="large">
        <div className="button-bar">
          <div
            className="d-flex flex-row justify-content-end p-1"
            style={{ position: "relative", width: "100vw", background: "rgba(0, 0, 0, 0.44)" }}
          >
            {/* <a className="mr-3" href={blobVideo} target="_blank" download rel="noreferrer">
              <Icon className="icon-image-download" name="download" />
            </a> */}
            <a onClick={() => setShowVideo(false)} className="mr-2">
              <Icon name="cross" className="icon-image-download" />
            </a>
          </div>
        </div>
        {/* <img className="rounded" src={link} style={{ height: "100%" }} alt={link} /> */}
        <video preload="auto" controls style={{ height: "90vh" }}>
          <source src={link} />
        </video>
      </Modal>
    </React.Fragment>
  );
};

export const SharedDoc = ({ link, extension }) => {
  return (
    <li>
      <a href={link} target="_blank" className="sidebar-document" rel="noreferrer">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="sidebar-document-title">{extension}</div>
        </div>
      </a>
    </li>
  );
};

export const QuickReplyItem = ({ command, content, clickEvent, mouseEnterEvent, mouseLeaveEvent, active }) => {
  return (
    <Row
      className={classNames({ "quick-reply-item-active": active }, "py-1 quick-reply-item")}
      onClick={clickEvent}
      onMouseEnter={mouseEnterEvent}
      onMouseLeave={mouseLeaveEvent}
    >
      <Col md="2" lg="2" className="quick-reply-command pl-4">
        {command}
      </Col>
      <Col md="10" lg="10">
        {content}
      </Col>
    </Row>
  );
};
