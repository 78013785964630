import React, { useState, useEffect, useContext } from "react";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import KanbanBoard from "./KanbanBoard";
import { Modal } from "reactstrap";
import { BlockHead, BlockBetween, BlockHeadContent, BlockTitle, Icon, Block } from "components/Component";
import { KanbanBoardForm, KanbanTaskForm } from "./KanbanForms";
import KanbanFilter from "./KanbanFilter";
import { serviceFilterLeads } from "services/leads";
import { useCookies } from "react-cookie";
import moment from "moment";
import { DefaultKeyLeadsFilter } from "data/DefaultLeadFilters";
import { MessagesContext } from "contexts/messagesContext";
import { serviceGetChatById } from "services/chats";

const Kanban = () => {
  const [kanbanFilter, setKanbanFilter] = useState(DefaultKeyLeadsFilter);
  const [columns, setColumns] = useState(null);
  const [boardModal, setBoardModal] = useState(false);
  const [smBtn, setSmBtn] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [cookies] = useCookies(["user"]);
  const [loading, setLoading] = useState(true);
  const [departmentsFilter, setDepartmentsFilter] = useState(cookies.departmentId);
  const [dataLeads, setDdataLeads] = useState("");
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [changeOption, setChangeOption] = useState(1);
  const [selectedSort, setSelectedSort] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["lead-monitorings"].access || [];
  const { messagesValue, salesUpdate } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;
  const [newSales] = salesUpdate;

  const toggleBoardModal = () => {
    setBoardModal(!boardModal);
  };

  const toggleTaskModal = () => {
    setTaskModal(!taskModal);
  };

  useEffect(() => {
    refreshData(startDate, endDate);
  }, []);

  useEffect(() => {
    refreshData(startDate, endDate);
  }, [departmentsFilter]);

  useEffect(() => {
    refreshData(startDate, endDate);
  }, [changeOption]);

  useEffect(() => {
    refreshData(startDate, endDate);
  }, [selectedSort]);

  const sortDataSales = (data, sortParam) => {
    data.sort((a, b) => {
      let fa = a[sortParam].toLowerCase(),
        fb = b[sortParam].toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return data;
  };

  const sortData = (data, sortParam) => {
    if (sortParam.key === "chats") {
      data.sort((a, b) => {
        let fa = a[sortParam.val].toLowerCase(),
          fb = b[sortParam.val].toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    } else if (sortParam === "default") {
      data.sort((a, b) => {
        let fa = a.updatedAt,
          fb = b.updatedAt;
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort((a, b) => {
        let fa = a.contact[sortParam.val]?.toLowerCase(),
          fb = b.contact[sortParam.val]?.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    }
    return data;
  };

  const colorStatus = (status) => {
    if (status === "UNOPENED") {
      return "danger";
    } else if (status === "RESOLVED") {
      return "success";
    } else if (status === "PENDING") {
      return "warning";
    }
    return "primary";
  };

  const isMetaArray = (data) => {
    if (!data) return [];
    if (!data.metadata) return [];
    if (!Array.isArray(data.metadata)) return [];
    return data.metadata.map((i) => {
      return i.item.product_name;
    });
  };

  const mapingData = (data) => {
    if (data && selectedSort && selectedSort.val === "sales") {
      sortDataSales(data, "firstName");
    }
    var newCol = { task: {}, columns: {}, columnOrder: [] };
    data.map((el, idx) => {
      if (data && selectedSort && selectedSort.val !== "sales") {
        sortData(el.chats, selectedSort);
      } else {
        sortData(el.chats, "default");
      }
      el.chats.map((ch) => {
        newCol.task[ch.id] = {
          id: ch.id,
          title: ch.contact.isSaved ? ch.contact.name : ch.customerPhone,
          desc: "Note : " + (ch.contact.notes || "-"),
          board: "To Review",
          meta: {
            users: [
              { value: "Ober Mayers", label: "Ober Mayers", theme: "purple" },
              { value: "Sergei Surnama", label: "Sergei Surnama", theme: "success" },
            ],
            tags: [{ value: ch.status, label: ch.status, theme: colorStatus(ch.status) }],
            due: "1",
            sales: el.firstName + " " + el.lastName,
            salesId: el.id,
            category: "Backend",
            product: isMetaArray(ch.contact),
            comment: "9",
            attachment: "1",
            address: ch.contact.address || "Address : -",
            city: ch.contact.district || "City : -",
            startDate: moment(ch.createdAt).format("MMM DD, YYYY. HH:mm"),
            endDate: moment(ch.updatedAt).format("MMM DD, YYYY. HH:mm"),
            email: ch.contact.email || "-",
            phone: ch.customerPhone,
            platform: "Instagram",
          },
        };
      });
      newCol.columns[idx + 1] = {
        id: `column-lead-${idx + 1}`,
        userId: el.id,
        theme: "primary",
        text: el.firstName + " " + el.lastName,
        tasks: el.chats.map((ch) => ch.id),
      };
      newCol.columnOrder.push(`${idx + 1}`);
      //console.log('newCol', newCol);
    });

    return newCol;
  };

  const refreshData = async (startDate, endDate) => {
    setLoading(true);
    const resp = await serviceFilterLeads(
      cookies.SIDikey,
      roleFunctions,
      departmentsFilter,
      cookies.id,
      cookies.departmentIds,
      1,
      selectedFilterOptions,
      startDate,
      endDate
    );
    const cardData = resp.cardData.filter((item) => item.chats.length > 0);
    setDdataLeads(resp.filterData);
    var newCol = mapingData(cardData);
    setColumns(newCol);
    setLoading(false);
  };
  
  // Check choosen departmentId
  // console.log("departmentsFilter", departmentsFilter)
  
  useEffect(() => {
    if (newMessages.length > 0 && columns) {
      const lastMessage = newMessages.at(-1);
      if(lastMessage.type === "DOCUMENT" || lastMessage.type === "IMAGE" || lastMessage.type === "VIDEO") return false

      if ( lastMessage.type === "tag-update" || (newMessages.at(-1).fromMe && lastMessage.type === "TEXT")) return false;
      console.log("Socket : ", lastMessage)

      if(lastMessage.chat.channel.departmentId !== departmentsFilter) return false

      const chatList = { ...columns };
      const chatId = lastMessage.chat?.id ? lastMessage.chat.id : lastMessage.chatId
      const targetChat = chatList.task[chatId];

      const findOldSales = Object.keys(columns.columns).find((key) =>
        columns.columns[key].tasks.includes(chatId)
      );
      const findNewSales = Object.keys(columns.columns).find(
        (key) => columns.columns[key].userId === lastMessage.chat.userId
      );

      // Update chat status after resolve
      if(lastMessage.type === "tag-update-resolve") {
        if(targetChat) {
          targetChat.meta.tags = [
            // update status for sales
            {
              label: "RESOLVED",
              theme: colorStatus("RESOLVED"),
              value: "RESOLVED",
            },
          ];
        }
        return false
      }

      if (targetChat && findOldSales) {
        // card exist on list
        const findChatOnOldSales = columns.columns[findOldSales].tasks.findIndex(
          (item) => item === lastMessage.chat.id
        );
        targetChat.meta.tags = [
          // update status for sales
          {
            label: lastMessage.chat.status,
            theme: colorStatus(lastMessage.chat.status),
            value: lastMessage.chat.status,
          },
        ];
        if (lastMessage.chat.user.id !== chatList?.columns[findOldSales].userId) {
          // sales change based on message
          chatList.columns[findOldSales].tasks.splice(findChatOnOldSales, 1); // remove card for old sales
          if (findNewSales) {
            chatList.columns[findNewSales].tasks.unshift(lastMessage.chat.id); // add to new sales
            targetChat.meta.tags = [
              // update status for sales
              {
                label: lastMessage.chat.status,
                theme: colorStatus(lastMessage.chat.status),
                value: lastMessage.chat.status,
              },
            ];
          }
        }else{
          try {
            const ch = lastMessage.chat;
            chatList.task[lastMessage.chat.id] = {
              ...chatList.task[lastMessage.chat.id], 
              meta: {
                ...chatList.task[lastMessage.chat.id].meta,  // Preserve existing meta properties
                tags: [{ value: ch.status, label: ch.status, theme: colorStatus(ch.status) }],
                endDate: moment(ch.updatedAt).format("MMM DD, YYYY. HH:mm"),
              }
            };
          } catch (error) {
            console.log('Error not find old sales', error)
          }
        }
        setColumns({ ...chatList });
      } else {
        if (findNewSales) {
          // add new card
          const ch = lastMessage.chat;
          chatList.task[lastMessage.chat.id] = {
            id: ch.id,
            title: ch.name,
            desc: "Note : -",
            board: "To Review",
            meta: {
              users: [
                { value: "Ober Mayers", label: "Ober Mayers", theme: "purple" },
                { value: "Sergei Surnama", label: "Sergei Surnama", theme: "success" },
              ],
              tags: [{ value: ch.status, label: ch.status, theme: colorStatus(ch.status) }],
              due: "1",
              sales: ch.user.firstName + " " + ch.user.lastName,
              salesId: ch.userId,
              category: "Backend",
              product: "",
              comment: "9",
              attachment: "1",
              address: "Address : -",
              city: "City : -",
              startDate: moment(ch.createdAt).format("MMM DD, YYYY. HH:mm"),
              endDate: moment(ch.updatedAt).format("MMM DD, YYYY. HH:mm"),
              email: "-",
              phone: ch.customerPhone,
              platform: "Instagram",
            },
          };
          chatList.columns[findNewSales].tasks.unshift(lastMessage.chat.id);
          setColumns({ ...chatList });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessages]);

  const getNewChat = async (sales, chatList, findNewSales) => {
    const resNewChat = await serviceGetChatById(cookies.SIDikey, sales.chatId);
    if (resNewChat.status !== 200) return;
    const ch = resNewChat.data;
    chatList.task[sales.chatId] = {
      id: ch.id,
      title: ch.name,
      desc: "Note : -",
      board: "To Review",
      meta: {
        users: [
          { value: "Ober Mayers", label: "Ober Mayers", theme: "purple" },
          { value: "Sergei Surnama", label: "Sergei Surnama", theme: "success" },
        ],
        tags: [{ value: ch.status, label: ch.status, theme: colorStatus(ch.status) }],
        due: "1",
        sales: ch.user.firstName + " " + ch.user.lastName,
        salesId: ch.userId,
        category: "Backend",
        product: "",
        comment: "9",
        attachment: "1",
        address: "Address : -",
        city: "City : -",
        startDate: moment(ch.createdAt).format("MMM DD, YYYY. HH:mm"),
        endDate: moment(ch.updatedAt).format("MMM DD, YYYY. HH:mm"),
        email: "-",
        phone: ch.customerPhone,
        platform: "Instagram",
      },
    };
    chatList.columns[findNewSales].tasks.unshift(sales.chatId);
    setColumns(chatList);
  };

  useEffect(() => {
    if (!salesUpdate[0] || !columns) {
      return;
    }
    salesUpdate.map((salesArr) => {
      if (!salesArr) {
        return false;
      }
      salesArr.map((sales) => {
        if (!sales) {
          return false;
        }
        if (columns) {
          const chatList = { ...columns };
          const targetChat = chatList.task[sales.chatId];
          const findChatOldSales = Object.keys(columns.columns).find((key) =>
            columns.columns[key].tasks.includes(sales.chatId)
          );
          const findNewSales = Object.keys(columns.columns).find((key) => columns.columns[key].userId === sales.userId);
          
          if (findChatOldSales) {
            // klw chat ditemukan di sales
            const findChatOnOldSales = columns.columns[findChatOldSales].tasks.findIndex(
              (item) => item === sales.chatId
            );
            if (columns.columns[findChatOldSales].userId !== sales.userId) {
              // klw chat baru tidak sama dengan sales lama
              chatList.columns[findChatOldSales].tasks.splice(findChatOnOldSales, 1);
            }
            setColumns(chatList);
          }
          if (findNewSales) {
            if (!targetChat) {
              getNewChat(sales, chatList, findNewSales);
            } else {
              const findChatonNewsales = chatList.columns[findNewSales].tasks.find((item) => item === sales.chatId);
              if (!findChatonNewsales) {
                chatList.columns[findNewSales].tasks.unshift(sales.chatId);
                setColumns(chatList);
              }
            }
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSales]);

  const handleDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      refreshData(start, end);
    } else {
      refreshData(null, null);
    }
  };

  return (
    <React.Fragment>
      <Head title="Leads Monitoring"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Leads Monitoring</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#toggle"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmBtn(!smBtn);
                  }}
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                >
                  <Icon name="menu-alt-r"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <KanbanFilter
          setKanbanFilter={setKanbanFilter}
          departmentsFilter={departmentsFilter}
          setDepartmentsFilter={setDepartmentsFilter}
          cookies={cookies}
          dataLeads={dataLeads}
          selectedFilterOptions={selectedFilterOptions}
          setSelectedFilterOptions={setSelectedFilterOptions}
          changeOption={changeOption}
          setChangeOption={setChangeOption}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          handleDate={handleDate}
          startDate={startDate}
          endDate={endDate}
        />

        <Block>
          <div className="nk-kanban">
            {loading ? (
              <p>Loading</p>
            ) : (
              <KanbanBoard kanbanFilter={kanbanFilter} columns={columns} setColumns={setColumns} />
            )}
          </div>
        </Block>

        <Modal size="lg" isOpen={boardModal} toggle={toggleBoardModal}>
          <KanbanBoardForm toggle={toggleBoardModal} data={columns} setData={setColumns} />
        </Modal>

        <Modal size="lg" isOpen={taskModal} toggle={toggleTaskModal}>
          <KanbanTaskForm toggle={toggleTaskModal} data={columns} setData={setColumns} />
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default Kanban;
